import './NavigationSidebar.scss';

import { useLocation, useNavigate } from 'react-router';

import Dropdown from '../../common/dropdowns/Dropdown';
import Sidebar from './Sidebar';
import { NavigationItemKey, NavigationItem } from './NavigationSidebarConstants';
import AccordionButton from '../../common/buttons/AccordionButton';
import NavItemContent from './NavItemContent';
import NavSubItem from './NavSubItem';
import useAuth from '../../../context/AuthContext';
import OutlineButton from '../../common/buttons/OutlineButton';
import { OptionType } from '../../common/dropdowns/DropdownConstants';
import NavigationConsumer from '../../../context/NavigationContext';

interface NavigationSidebarProps {
  sidebarNavigationItems?: NavigationItem[],
  showSidebarHeader?: boolean,
  businessOptions: OptionType<string>[],
  selectedBusinessId: string,
  isLoggedOut?: boolean,
}

function getCounts(key: NavigationItemKey): number | null {
  switch (key) {
    default:
      return null;
  }
}

function NavigationSidebar({ sidebarNavigationItems = [], selectedBusinessId, businessOptions, isLoggedOut = false } : Readonly<NavigationSidebarProps>) {
  const { pathname } = useLocation();
  const { selectBusinessId, logout } = useAuth();
  const { navigationSubCounts } = NavigationConsumer();
  const navigate = useNavigate();

  const selectedNavOption = sidebarNavigationItems.find(({ prefix }) => pathname.startsWith(prefix));
  const selectedSubNavItem = selectedNavOption ? selectedNavOption.subItems.find(({ href }) => pathname === href) : null;
  const selectedNavKey = selectedNavOption ? selectedNavOption.key : null;
  const selectedSubNavKey = selectedSubNavItem ? selectedSubNavItem.key : null;
  const navItems = sidebarNavigationItems.map(({ key, displayText, icon, selectedIcon, href, subItems, hasBottomDivider = false, categoryHeader }) => {
    const onClick = () => {
      navigate(href);
    }
    const count = getCounts(key);
    const selected = selectedNavKey === key;
    const buttonContent = <NavItemContent icon={icon} selectedIcon={selectedIcon} text={displayText} selected={selected} count={count} />;
    const navSubItems = subItems.map(({ key, displayText, href }) => {
      const onClick = () => {
        navigate(href);
      }
      const count = navigationSubCounts[key];
      const selected = selectedSubNavKey === key;
      return <NavSubItem key={key} text={displayText} selected={selected} onClick={onClick} count={count} />
    });
    const empty = subItems.length === 0;
    const containerClassNames = ['nav-item-content-container', hasBottomDivider ? 'bottom-divider' : '', empty ? 'empty' : ''].join(' ');
    return (
      <section className={`nav-container ${hasBottomDivider ? 'section-end' : ''}`} key={key}>
        { categoryHeader && <span className={'category-header'}>{categoryHeader}</span> }
        <AccordionButton buttonContent={buttonContent} onClick={onClick} open={selected} additionalClassNames={[containerClassNames]}>
          {navSubItems}
        </AccordionButton>
      </section>
    )
  });
  const onLogoutClick = () => {
    if (isLoggedOut) {
      navigate('/login');
    }
    else {
      logout();
    }
  }
  return (
    <Sidebar additionalClassNames={['navigation-sidebar']}>
      <section className={'sidebar-body'}>
      { businessOptions.length > 0 && (
        <section className={'sidebar-header'}>
          <Dropdown
            options={businessOptions}
            defaultDisplayText={'Select business'}
            selected={selectedBusinessId}
            select={selectBusinessId}
          />
        </section>
      )}
        {navItems}
      </section>
      <section className={'logout-button-container'}>
        <OutlineButton content={isLoggedOut ? 'Log in' : 'Log out'} onClick={onLogoutClick} />
      </section>
    </Sidebar>
  )
};

export default NavigationSidebar;