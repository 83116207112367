import './DashboardPage.scss';

import { ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';

import CaseStudyCardsContainer from '../common/CaseStudyCardsContainer';
import DashboardMetrics from '../common/DashboardMetrics';

interface DashboardPageProps {
  isLoggedOut?: boolean,
}

function DashboardPage({ isLoggedOut = false } : DashboardPageProps): ReactElement<DashboardPageProps> {
  const [searchParams] = useSearchParams();
  const businessId = isLoggedOut ? searchParams.get('business_id') : undefined;
  return (
    <article className={'dashboard-page'}>
      <section className={'page'}>
        <section className={'dashboard'}>
          <header className={'dashboard-header'}>
            <h2 className={'title'}>{'Dashboard'}</h2>
            <h3 className={'subtitle'}>{'Overview of your account'}</h3>
          </header>
          <section className={'dashboard-page-content'}>
            <DashboardMetrics businessId={businessId} />
            <CaseStudyCardsContainer />
          </section>
        </section>
      </section>
    </article>
  );
}

export default DashboardPage;