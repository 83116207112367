import './Tooltip.scss';

import { ReactNode, useState, useRef } from 'react';
import { Portal } from '../../../hooks/PortalHooks';

interface TooltipProps {
  children: ReactNode | string,
  content: ReactNode | string,
  additionalClassNames?: string[],
  disabled?: boolean,
  isFlipped?: boolean,
};

/**
  * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
  * 
  * @param {String} text The text to be rendered.
  * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
  * 
  * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
  */
function getTextWidth(text: string, font: string): number {
  // re-use canvas object for better performance
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (context) {
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }
  return 0;;
}

function Tooltip({ children, content, additionalClassNames = [], disabled = false, isFlipped = false } : TooltipProps) {
  const [coords, setCoords] = useState({ left: 0, top: 0 });
  const tooltipContainer = useRef<HTMLElement>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const classNames = ['tooltip-container', ...additionalClassNames, disabled ? 'disabled' : ''];
  const onMouseEnter = () => {
    if (tooltipContainer.current) {
      const rect = tooltipContainer.current.getBoundingClientRect();
      let left = rect.left + rect.width / 2;
      if (typeof content === 'string') {
        left = isFlipped ? rect.left - getTextWidth(content, '500 15px Inter, Arial, sans-serif') - rect.width / 2 : rect.left + rect.width / 2;
      }
      setCoords({
        left: left,
        top: rect.top + rect.height
      });
      setShowTooltip(true);
    }
  };
  const onMouseLeave = () => setShowTooltip(false);
  return (
    <section className={classNames.join(' ')} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} ref={tooltipContainer}>
      <section className={'tooltip-item'}>
        {children}
      </section>
      { showTooltip && !disabled &&
        <Portal>
          <section className={'tooltip'} style={{ left: coords.left, top: coords.top }}>
            <section className={'tooltip-content'}>
              {content}
            </section>
          </section>
        </Portal>
      }
    </section>
  );
};

export default Tooltip;