import './InboxRecipientCard.scss';

import { InboxRecipientWithLatestMessage } from '../../../people/PeopleConstants';

import BlackTag from '../../../common/tags/BlackTag';
import UnreadIndicator from './inbox/UnreadIndicator';
import { determineMessageChannel } from '../helpers/InboxHelpers';

interface InboxRecipientCardProps {
  lastRecipientRef?: (node: HTMLDivElement | null) => void,
  recipient: InboxRecipientWithLatestMessage,
  selectRecipient: () => void,
  isSelected: boolean,
}

const NO_LATEST_MESSAGE = {
  message: '',
  channel: null,
  creationDate: null,
}

function InboxRecipientCard({ lastRecipientRef, recipient, selectRecipient, isSelected } : InboxRecipientCardProps) {
  const { firstName, lastName, latestMessage, unreadCount } = recipient;
  const fullName = `${firstName} ${lastName}`;
  const { message, channel, creationDate } = latestMessage ? latestMessage : NO_LATEST_MESSAGE;
  const messageChannel = channel ? channel : determineMessageChannel(recipient);
  const messageChannelToDisplay = messageChannel ? messageChannel : 'None';
  const timestamp = creationDate ? new Date(creationDate).toLocaleString() : '';
  const formattedMessage = message.length > 50 ? `${message.slice(0, 50)}...` : message;
  const messageToDisplay = formattedMessage ? formattedMessage : 'No Recent Messages';

  return (
    <section ref={lastRecipientRef} className={`inbox-card ${isSelected ? 'selected' : ''}`} onClick={selectRecipient}>
      <section className={'inbox-card-header'}>
        <section className={'inbox-recipient'}>
          {fullName}
          {unreadCount > 0 && !isSelected && <UnreadIndicator />}
        </section>
        <section className={'inbox-timestamp'}>{timestamp}</section>
      </section>
      <section className={'inbox-card-body'}>{messageToDisplay}</section>
      <section className={'inbox-card-footer'}>
        <BlackTag content={messageChannelToDisplay} />
      </section>
    </section>
  )
}

export default InboxRecipientCard;