import './InformationIconTooltip.scss';

import { ReactElement } from 'react';

import Tooltip from './Tooltip';

import INFO_ICON from './assets/information-icon.svg';

interface InformationIconTooltipProps {
  tooltipText: string,
  isFlipped?: boolean,
}

function InformationIconTooltip({ tooltipText, isFlipped = false } : Readonly<InformationIconTooltipProps>): ReactElement<InformationIconTooltipProps> {
  return (
    <Tooltip content={tooltipText} additionalClassNames={['information-icon-tooltip']} isFlipped={isFlipped}>
      <img className={'information-icon'} src={INFO_ICON} alt={tooltipText} />
    </Tooltip>
  );
}

export default InformationIconTooltip;