import { useEffect, useState, Dispatch, SetStateAction } from 'react';

import { EnrichedRecoverClientMessage } from '../RecoverClientsConstants';
import { getRecoverClientMessagesForBusiness } from '../../../../apis/RecoverClientsApi';

interface ReactivateClientsData {
  reactivateClientMessages: EnrichedRecoverClientMessage[],
  setReactivateClientMessages: (reactivateClientMessages: EnrichedRecoverClientMessage[]) => void,
  loading: boolean,
  cursor: number,
  setCursor: Dispatch<SetStateAction<number>>,
};

export function useBusinessReactivateClientMessages(businessId: string | null): ReactivateClientsData {
  const [cursor, setCursor] = useState(0);
  const [reactivateClientMessages, setReactivateClientMessages] = useState<EnrichedRecoverClientMessage[]| null>(null);
  
  useEffect(() => {
    if (businessId) {
      setCursor(0);
      setReactivateClientMessages(null);
      getRecoverClientMessagesForBusiness(businessId).then(({ recoverClientMessages }) => setReactivateClientMessages(recoverClientMessages));
    }
  }, [businessId]);

  return {
    reactivateClientMessages: reactivateClientMessages || [],
    setReactivateClientMessages,
    loading: reactivateClientMessages === null,
    cursor,
    setCursor,
  };
}
