import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import useAuth from './AuthContext';
import { SubNavigationItemKey } from '../components/navigation/sidebar/NavigationSidebarConstants';
import { getUnreadMessageCount } from '../apis/MessageApi';

const DEFAULT_NAV_SUBCOUNTS = Object.keys(SubNavigationItemKey).reduce((subCountMap, subNavKey) => ({ ...subCountMap, [subNavKey]: null }), {});

const EmptyNav = {
  navigationSubCounts: DEFAULT_NAV_SUBCOUNTS,
  setNavigationSubCounts: () => {},
  updateNavigationCounts: false,
  setUpdateNavigationCounts: () => {},
}

interface NavProps {
  navigationSubCounts: { [key: string]: number | null },
  setNavigationSubCounts: React.Dispatch<React.SetStateAction<{}>>,
  updateNavigationCounts: boolean,
  setUpdateNavigationCounts: (updateNavigationCounts: boolean) => void,
}

const NavigationContext = createContext<NavProps>(EmptyNav);

export const NavigationProvider = ({ children } : { children: ReactNode }) => {
  const { selectedBusinessId } = useAuth();

  const [navigationSubCounts, setNavigationSubCounts] = useState(DEFAULT_NAV_SUBCOUNTS);
  const [updateNavigationCounts, setUpdateNavigationCounts] = useState(true);
  
  useEffect(() => {
    if (selectedBusinessId || (selectedBusinessId && updateNavigationCounts)) {
      getUnreadMessageCount(selectedBusinessId)
        .then((response) => {
          if (response.success) {
            const unreadCount = response.totalCount ? response.totalCount : null;
            setNavigationSubCounts({ ...navigationSubCounts, [SubNavigationItemKey.LEADS_INBOX]: unreadCount })
          }
        });
    }
    setUpdateNavigationCounts(false);
  }, [selectedBusinessId, updateNavigationCounts]);

  return (
    <NavigationContext.Provider value={{
      navigationSubCounts,
      setNavigationSubCounts,
      updateNavigationCounts,
      setUpdateNavigationCounts
    }}>
      {children}
    </NavigationContext.Provider>
  );
}

const NavigationConsumer = () => useContext(NavigationContext);

export default NavigationConsumer;