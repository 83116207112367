import './ChatTimestamp.scss';

import { getDatestampDisplay, getTimestampDisplay } from '../../InboxConstants';
import { nth } from '../../../../../../utils/DateHelpers';

interface ChatTimestampProps {
  timestamp: number,
  isReceiver: boolean
}

function ChatTimestamp({ timestamp, isReceiver } : ChatTimestampProps) {
  const timestampDisplay = getTimestampDisplay(timestamp);
  const dateStampDisplay = getDatestampDisplay(timestamp);
  const dateComponents = dateStampDisplay.split(' ');
  const formattedDate = `${dateComponents[0]}${nth(parseInt(dateComponents[0]))} ${dateComponents[1]}`
  return <span className={`chat-timestamp ${isReceiver ? 'receiver' : 'sender'}`}>{`${timestampDisplay.replace(' ', '')} ${formattedDate}`}</span>;
}

export default ChatTimestamp;