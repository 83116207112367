import { ReactElement } from 'react';

import { Metrics } from '../../../../types/MetricTypes';
import { LeadSource } from '../../../../types/LeadTypes';

import { DashboardMetric } from '../DashboardConstants';

import DashboardMetricList from './DashboardMetricList';

interface DashboardMetricListContainerProps {
  metricItems: DashboardMetric[]
  metrics: Metrics,
  keyId: string,
  title: string,
  openSettingsModal: ((leadSource: LeadSource) => void) | null
}

function DashboardMetricListContainer({ metricItems, metrics, keyId, title, openSettingsModal } : Readonly<DashboardMetricListContainerProps>): ReactElement<DashboardMetricListContainerProps> {
  return (
    <section className={'dashboard-metric-list-container'}>
      <span className={'metric-title'}>{title}</span>
      <section className={'dashboard-metric-list'}>
      <DashboardMetricList
        keyId={keyId}
        metricItems={metricItems}
        metrics={metrics}
        openSettingsModal={openSettingsModal}
      />
      </section>
    </section>
  )
}

export default DashboardMetricListContainer;