import './WorkRexPage.scss';

import { Outlet } from 'react-router';

import useAuth from '../../context/AuthContext';
import { CASE_STUDIES_NAVIGATION_ITEMS } from '../navigation/sidebar/NavigationSidebarConstants';

import { OptionType } from '../common/dropdowns/DropdownConstants';

import NavigationSidebar from '../navigation/sidebar/NavigationSidebar';
import LoadingRingPage from '../common/pages/LoadingRingPage';

function WorkRexPage() {
  const { user, selectedBusinessId } = useAuth();
  const businesses = user?.businesses || [];
  const businessOptions = businesses.map(({ _id, businessName }) => ({ displayText: businessName, value: _id } as OptionType<string>));
  if (!selectedBusinessId) {
    return <LoadingRingPage color={'#000000'} />;
  }
  return (
    <main className={'work-rex-page'}>
      { /** This element is used for portaling: https://ankitjoshi.hashnode.dev/react-portal-and-tooltip */}
      <section id={'portal-root'} />
      <NavigationSidebar
        sidebarNavigationItems={CASE_STUDIES_NAVIGATION_ITEMS}
        selectedBusinessId={selectedBusinessId}
        businessOptions={businessOptions}
      />
      <section className={'sidebar-buffer-container'}>
        <Outlet />
      </section>
    </main>
  )
}

export default WorkRexPage;