import './CharacteristicsNode.scss';

import { ReactElement } from 'react';

import { Gender, SkinTone } from '../CommercialOutreachConstants';

import Tooltip from '../../../common/tooltips/Tooltip';

import FemaleIcon from './assets/female-icon.svg';
import MaleIcon from './assets/male-icon.svg';
import GlassesIcon from './assets/glasses-icon.svg';

import LightSkinIcon from './assets/light-skin-icon.svg';
import MediumSkinIcon from './assets/medium-skin-icon.svg';
import DarkSkinIcon from './assets/dark-skin-icon.svg';

interface CharacteristicsNodeProps {
  approximateAge: number | null | undefined,
  approximateGender: Gender | null | undefined,
  skinTone: SkinTone | null | undefined,
  isWearingGlasses: boolean | null | undefined,
}

const GenderContent = {
  [Gender.Female]: {
    icon: FemaleIcon,
    tooltipText: 'The sex is likely female',
  },
  [Gender.Male]: {
    icon: MaleIcon,
    tooltipText: 'The sex is likely male',
  }
}

const SkinToneContent = {
  [SkinTone.Light]: {
    icon: LightSkinIcon,
    tooltipText: 'This person is likely to have a fair complexion',
  },
  [SkinTone.Medium]: {
    icon: MediumSkinIcon,
    tooltipText: 'This person is likely to have a medium complexion',
  },
  [SkinTone.Dark]: {
    icon: DarkSkinIcon,
    tooltipText: 'This person is likely to have a darker complexion',
  }
};

interface AgeContent {
  text: string,
  tooltipText: string,
}

function getAgeContent(age: number | null | undefined): AgeContent | null {
  if (!age) {
    return null;
  }
  if (age >= 20 && age <= 35) {
    return {
      text: '20y+',
      tooltipText: 'This person is likely aged 20+',
    };
  }
  if (age >= 35 && age <= 55) {
    return {
      text: '35y+',
      tooltipText: 'This person is likely aged 35+',
    };
  }
  if (age >= 55) {
    return {
      text: '55y+',
      tooltipText: 'This person is likely aged 55+',
    };
  }
  return null;
}

function CharacteristicsNode({ approximateAge, approximateGender, skinTone, isWearingGlasses } : CharacteristicsNodeProps): ReactElement<CharacteristicsNodeProps> {
  const genderNode = approximateGender
    ?
      ( 
        <Tooltip content={GenderContent[approximateGender].tooltipText}>
          <img className={'gender-icon'} src={GenderContent[approximateGender].icon} alt={approximateGender} />
        </Tooltip>
      )
    : null;
  
  const glassesNode = isWearingGlasses
    ?
      (
        <Tooltip content={'This person is likely wearing glasses'}>
          <img className={'glasses-icon'} src={GlassesIcon} alt={'Glasses'} />
        </Tooltip>
      )
    : null;
  
  const ageContent = getAgeContent(approximateAge);

  const skinToneNode = skinTone
    ?
      (
        <Tooltip content={SkinToneContent[skinTone].tooltipText}>
          <img className={'skin-tone-icon'} src={SkinToneContent[skinTone].icon} alt={skinTone} />
        </Tooltip>
      )
    : null;
  const ageNode = ageContent !== null
    ?
      (
        <Tooltip content={ageContent.tooltipText}>
          <span className={'age-node'}>{ageContent.text}</span>
        </Tooltip>
      )
    : null;
  
  return (
    <section className={'characteristics-node'}>
      { genderNode }
      { glassesNode }
      { skinToneNode }
      { ageNode }
    </section>
  );
}

export default CharacteristicsNode;