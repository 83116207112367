import { ReactElement, useState } from 'react';

import ConfirmationModalContent from '../../../common/modals/ConfirmationModalContent';
import Modal from '../../../common/modals/Modal';

interface StopNurtureConfirmationModalProps {
  cancel: () => void,
  confirm: (setLoading: (loding: boolean) => void) => void,
};

function StopNurtureConfirmationModal({ cancel, confirm } : Readonly<StopNurtureConfirmationModalProps>): ReactElement<StopNurtureConfirmationModalProps> {
  const [loading, setLoading] = useState(false);
  return (
    <Modal>
      <ConfirmationModalContent
        title={'Stop nurturing this lead?'}
        subtitle={'This lead will no longer receive any future nurture messages.'}
        cancel={cancel}
        confirm={() => confirm(setLoading)}
        confirmText={'Confirm'}
        cancelText={'Cancel'}
        loading={loading}
      />
    </Modal>
  );
}

export default StopNurtureConfirmationModal;

