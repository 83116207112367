import { getData, postData } from './Api';
import { SuccessResponse } from './types/ResponseTypes';
import { HandwrittenLetterMetrics, CommercialOutreachMetrics, ReactivateLostClientsMetrics, BusinessEstimatedRevenueSettings } from '../types/MetricTypes';
import { LeadNurtureSettings, BusinessEvent, BusinessSubscription, LivingNearbySettings, LeanBusiness } from '../types/BusinessTypes';
import { EmailForwardingSettings, ReactivateLostClientConditions } from '../types/UserTypes';
import { BusinessLostClientAnalytics } from '../types/AnalyticTypes';
import { CommercialOutreachType } from '../types/BusinessTypes';
import { LeadSource } from '../types/LeadTypes';

const GetBusinessUrls = {
  GetBusinessMetricsUrl: '/api/business/metrics/',
  GetBusinessEventsUrl: '/api/business/events/',
  GetBusinessAnalyticsUrl: '/api/business/analytics/',
  GetBusinessSubscriptionUrl: '/api/business/subscription/',
  GetBusinessEstimatedRevenueSettingsUrl: '/api/business/estimated-revenue-settings/',
  GetLeanBusinessUrl: '/api/business/lean/',
}

const PostBusinessUrls = {
  UpdateBusinessRevenueEstimatorUrl: '/api/business/revenue/estimator/update',
  UpdateBusinessLeadNurtureSettingsUrl: '/api/business/lead/automatic-nurture/update',
  UpdateBusinessLostClientReactivationSettingsUrl: '/api/business/lost-clients/reactivation-conditions/update',
  UpdateBusinessLostClientEmailForwardingSettingsUrl: '/api/business/lost-clients/email-forwarding/update',
  UpdateBusinessLeadEmailForwardingSettingsUrl: '/api/business/lead/email-forwarding/update',
  UpdateBusinessLeadAutoResponseSettingsUrl: '/api/business/lead/auto-response/settings/update',
  UpdateBusinessLivingNearbySettingsUrl: '/api/business/living-nearby/settings/update',
  UpdateBusinessCommercialOutreachSettingsUrl: '/api/business/commercial-outreach/settings/update',
  UpdateBusinessCommercialOutreachLinkedInSettingsUrl: '/api/business/commercial-outreach/settings/linked-in/update',
};

const {
  UpdateBusinessRevenueEstimatorUrl,
  UpdateBusinessLeadNurtureSettingsUrl,
  UpdateBusinessLostClientReactivationSettingsUrl,
  UpdateBusinessLostClientEmailForwardingSettingsUrl,
  UpdateBusinessLeadEmailForwardingSettingsUrl,
  UpdateBusinessLeadAutoResponseSettingsUrl,
  UpdateBusinessLivingNearbySettingsUrl,
  UpdateBusinessCommercialOutreachSettingsUrl,
  UpdateBusinessCommercialOutreachLinkedInSettingsUrl,
} = PostBusinessUrls;

const {
  GetLeanBusinessUrl,
  GetBusinessMetricsUrl,
  GetBusinessEventsUrl,
  GetBusinessAnalyticsUrl,
  GetBusinessSubscriptionUrl,
  GetBusinessEstimatedRevenueSettingsUrl,
} = GetBusinessUrls;

// Request interfaces

/**
 * Interface for request involved in updating a business's revenue estimator fields.
 */
interface UpdateBusinessRevenueEstimatorRequest {
  businessId: string,
  leadToClientProportion: number,
  revenuePerClient: number,
  leadSource: LeadSource,
}

/**
 * Interface for request involved in updating a business's lead automatic nurture settings.
 */
interface UpdateBusinessLeadNurtureSettingsRequest {
  businessId: string,
  leadNurtureSettings: LeadNurtureSettings,
}

/**
 * Interface for request involved in updating a business's lost client reactivation conditions settings.
 */
interface UpdateBusinessLostClientSettingsRequest {
  businessId: string,
  reactivateLostClientConditions: ReactivateLostClientConditions[],
}

/**
 * Interface for request involved in updating a business's lost client reactivation conditions settings.
 * Also used for updating email forwarding settings for a business's lead email forwarding.
 */
interface UpdateBusinessEmailForwardingSettingsRequest {
  businessId: string,
  emailForwardingSettings: EmailForwardingSettings,
}

/**
 * Interface for request involved in updating a business's lost client reactivation conditions settings.
 * Also used for updating email forwarding settings for a business's lead email forwarding.
 */
interface UpdateBusinessLeadAutoResponseSettingsRequest {
  businessId: string,
  leadAutoResponse: boolean,
}

/**
 * Interface for request involved in updating a business's living nearby settings.
 */
interface UpdateBusinessLivingNearbySettingsRequest {
  businessId: string,
  suburbs: string[],
}

/**
 * Interface for request involved in updating a business's lost client reactivation conditions settings.
 */
interface UpdateBusinessCommercialOutreachSettingsRequest {
  businessId: string,
  commercialOutreachSettings: CommercialOutreachType,
}

// Response interfaces

/**
 * Interface for business metrics fetch response. 
 * Expects handwritten letter, reactivate lost clients, and commercial outreach metrics.
 */
interface FetchBusinessAnalyticsResponse extends SuccessResponse {
  reactivateClientAnalytics: BusinessLostClientAnalytics
}

/**
 * Interface for business metrics fetch response. 
 * Expects handwritten letter, reactivate lost clients, and commercial outreach metrics.
 */
interface FetchBusinessMetricsResponse extends SuccessResponse {
  handwrittenLetterMetrics: HandwrittenLetterMetrics,
  reactivateLostClientsMetrics: ReactivateLostClientsMetrics,
  commercialOutreachMetrics: CommercialOutreachMetrics,
}


/**
 * Interface for business events fetch response.
 */
interface FetchBusinessEventsResponse extends SuccessResponse {
  businessEvents: BusinessEvent[],
}

/**
 * Interface for business events fetch response.
 */
interface FetchLeanBusinessResponse extends SuccessResponse {
  business: LeanBusiness,
}

/**
 * Interface for business subscription fetch response.
 */
interface FetchBusinessSubscriptionResponse extends SuccessResponse {
  subscription: BusinessSubscription,
}

/**
 * Interface for business subscription fetch response.
 */
interface FetchBusinessEstimatedRevenueSettingsResponse extends SuccessResponse {
  estimatedRevenueSettings: BusinessEstimatedRevenueSettings,
}

// GET requests

/**
 * Get lean business data (business name and id fields). Adds the business id as a request parameter.
 */
export async function getLeanBusiness(businessId: string): Promise<FetchLeanBusinessResponse> {
  return getData<FetchLeanBusinessResponse>(`${GetLeanBusinessUrl}${businessId}`);
}

/**
 * Get business metrics data from backend. Adds the business id as a request parameter.
 */
export async function getBusinessMetrics(businessId: string): Promise<FetchBusinessMetricsResponse> {
  return getData<FetchBusinessMetricsResponse>(`${GetBusinessMetricsUrl}${businessId}`);
}

/**
 * Get business events data from backend. Adds the business id as a request parameter.
 */
export async function getBusinessEvents(businessId: string): Promise<FetchBusinessEventsResponse> {
  return getData<FetchBusinessEventsResponse>(`${GetBusinessEventsUrl}${businessId}`);
}

/**
 * Get business metrics data from backend. Adds the business id as a request parameter.
 */
export async function getBusinessAnalytics(businessId: string): Promise<FetchBusinessAnalyticsResponse> {
  return getData<FetchBusinessAnalyticsResponse>(`${GetBusinessAnalyticsUrl}${businessId}`);
}

/**
 * Get business subscription data from backend. Adds the business id as a request parameter.
 */
export async function getBusinessSubscription(businessId: string): Promise<FetchBusinessSubscriptionResponse> {
  return getData<FetchBusinessSubscriptionResponse>(`${GetBusinessSubscriptionUrl}${businessId}`);
}

/**
 * Get business subscription data from backend. Adds the business id as a request parameter.
 */
export async function getBusinessEstimatedRevenueSettings(businessId: string): Promise<FetchBusinessEstimatedRevenueSettingsResponse> {
  return getData<FetchBusinessEstimatedRevenueSettingsResponse>(`${GetBusinessEstimatedRevenueSettingsUrl}${businessId}`);
}

// POST Requests

/**
 * Posts an update to business's lead automatic nurture settings.
 * These settings are used to automatically nurture leads based on their source.
 */
export async function postUpdateLeadNurtureSettings(
  businessId: string,
  leadNurtureSettings: LeadNurtureSettings
): Promise<SuccessResponse> {
  return postData<UpdateBusinessLeadNurtureSettingsRequest, SuccessResponse>(
    UpdateBusinessLeadNurtureSettingsUrl, { businessId, leadNurtureSettings }
  );
}

/**
 * Posts an update to business's revenue estimator fields:
 * @param businessId: The business id to update.
 * @param leadToClientProportion: The proportion of clients that are generated from leads.
 * @param revenuePerClient: The revenue generated per client.
 * @param leadSource: The source of the lead
 */
export async function postUpdateBusinessRevenueEstimator(
  businessId: string,
  leadToClientProportion: number,
  revenuePerClient: number,
  leadSource: LeadSource
): Promise<SuccessResponse> {
  return postData<UpdateBusinessRevenueEstimatorRequest, SuccessResponse>(
    UpdateBusinessRevenueEstimatorUrl,
    { businessId, leadToClientProportion, revenuePerClient, leadSource }
  );
}

/**
 * Posts an update to business's reactivate lost client condition field:
 * @param businessId: The business id to update.
 * @param reactivateLostClientConditions: The list of conditions at which a client becomes a lost client.
 */
export async function postUpdateBusinessLostClientCondition(
  businessId: string,
  reactivateLostClientConditions: ReactivateLostClientConditions[],
): Promise<SuccessResponse> {
  return postData<UpdateBusinessLostClientSettingsRequest, SuccessResponse>(
    UpdateBusinessLostClientReactivationSettingsUrl,
    { businessId, reactivateLostClientConditions }
  );
}

/**
 * Posts an update to business's reactivate lost client condition field:
 * @param businessId: The business id to update.
 * @param emailForwardingSettings: The list of conditions at which a client becomes a lost client.
 */
export async function postUpdateBusinessEmailForwardingSettings(
  businessId: string,
  emailForwardingSettings: EmailForwardingSettings,
): Promise<SuccessResponse> {
  return postData<UpdateBusinessEmailForwardingSettingsRequest, SuccessResponse>(
    UpdateBusinessLostClientEmailForwardingSettingsUrl,
    { businessId, emailForwardingSettings }
  );
}

/**
 * Posts an update to business's living nearby settings:
 * @param businessId: The business id to update.
 * @param livingNearbySettings: The list of suburbs that a business wants us to target.
 */
export async function postUpdateBusinessLivingNearbySettings(
  businessId: string,
  livingNearbySettings: LivingNearbySettings,
): Promise<SuccessResponse> {
  return postData<UpdateBusinessLivingNearbySettingsRequest, SuccessResponse>(
    UpdateBusinessLivingNearbySettingsUrl,
    { businessId, suburbs: livingNearbySettings.suburbs }
  );
}

/**
 * Posts an update to business's commercial outreach linkedIn settings and updating airtable:
 * @param businessId: The business id to update.
 * @param commercialOutreachSettings: The commercial outreach settings with the specific linkedIn credential changes.
 */
export async function postUpdateBusinessCommercialOutreachLinkedInSettings(
  businessId: string,
  commercialOutreachSettings: CommercialOutreachType,
): Promise<SuccessResponse> {
  return postData<UpdateBusinessCommercialOutreachSettingsRequest, SuccessResponse>(
    UpdateBusinessCommercialOutreachLinkedInSettingsUrl,
    { businessId, commercialOutreachSettings }
  );
}

/**
 * Posts an update to business's commercial outreach settings field:
 * @param businessId: The business id to update.
 * @param commercialOutreachSettings: The commerical outreach settings to update on the business.
 */
export async function postUpdateBusinessCommercialOutreachSettings(
  businessId: string,
  commercialOutreachSettings: CommercialOutreachType,
): Promise<SuccessResponse> {
  return postData<UpdateBusinessCommercialOutreachSettingsRequest, SuccessResponse>(
    UpdateBusinessCommercialOutreachSettingsUrl,
    { businessId, commercialOutreachSettings }
  );
}

/**
 * Posts an update to business's lead email forwarding settings.
 */
export async function postUpdateLeadEmailForwardingSettings(
  businessId: string,
  leadEmailForwardingSettings: EmailForwardingSettings,
): Promise<SuccessResponse> {
  return postData<UpdateBusinessEmailForwardingSettingsRequest, SuccessResponse>(
    UpdateBusinessLeadEmailForwardingSettingsUrl, { businessId, emailForwardingSettings: leadEmailForwardingSettings }
  );
}

/**
 * Posts an update to business's lead email forwarding settings.
 */
export async function postUpdateLeadAutoResponseSettings(
  businessId: string,
  leadAutoResponse: boolean,
): Promise<SuccessResponse> {
  return postData<UpdateBusinessLeadAutoResponseSettingsRequest, SuccessResponse>(
    UpdateBusinessLeadAutoResponseSettingsUrl, { businessId, leadAutoResponse }
  );
}