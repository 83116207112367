import './DashboardMetrics.scss';

import { ReactElement, useRef, useState } from 'react';

import { useOutsideClick } from '../../../../hooks/ClickHooks';
import { getBusinessMetrics } from '../../../../apis/BusinessApi';
import { useBusinessEstimatedRevenueSettings, useBusinessMetrics } from '../hooks/DashboardHooks';
import useAuth from '../../../../context/AuthContext';
import { LeadSource } from '../../../../types/LeadTypes';

import LoadingRing from '../../../common/loading/LoadingRing';

import {
  DefaultCommercialOutreachMetrics,
  DefaultHandwrittenLetterMetrics,
  DefaultReactivateLostClientsMetrics,
  ReactivateLostClientsMetricItems,
  HandwrittenLetterMetricItems,
  CommercialOutreachMetricItems,
} from '../DashboardConstants';

import DashboardMetricListContainer from '../common/DashboardMetricListContainer';
import DashboardRevenueEditModal from '../modals/DashboardRevenueEditModal';

interface DashboardMetricsProps {
  businessId?: string | null;
}

function DashboardMetrics({ businessId }: DashboardMetricsProps): ReactElement<DashboardMetricsProps> {
  const { selectedBusinessId } = useAuth();
  const dashboardBusinessId = businessId || selectedBusinessId;
  const { loading, metrics, setMetrics } = useBusinessMetrics(dashboardBusinessId);
  const { estimatedRevenueSettings, setEstimatedRevenueSettings } = useBusinessEstimatedRevenueSettings(dashboardBusinessId);
  const [selectedLeadSource, selectLeadSource] = useState<LeadSource | null>(null);

  // We should hide the open settings modal if the business id is not set
  const openSettingsModal = (leadSource: LeadSource) => selectLeadSource(leadSource);
  const closeSettingsModal = () => selectLeadSource(null);
  const modalRef = useRef(null);
  useOutsideClick(modalRef, closeSettingsModal);
  const handwrittenLetterMetrics = metrics?.handwrittenLetterMetrics || DefaultHandwrittenLetterMetrics;
  const reactivateLostClientsMetrics = metrics?.reactivateLostClientsMetrics || DefaultReactivateLostClientsMetrics;
  const commercialOutreachMetrics = metrics?.commercialOutreachMetrics || DefaultCommercialOutreachMetrics;

  const leadToClientProportionText = selectedLeadSource && estimatedRevenueSettings
    ? estimatedRevenueSettings.leadToClientProportion[selectedLeadSource].toString() : '';
  const revenuePerClientText = selectedLeadSource && estimatedRevenueSettings
  ? estimatedRevenueSettings.revenuePerClient[selectedLeadSource].toString() : '';

  const setLeadToClientProportion = (leadToClientProportion: string, leadSource: LeadSource) => {
    if (estimatedRevenueSettings) {
      const formattedLeadToClientProportion = leadToClientProportion.replace(/\D/g,'');
      const updatedLeadToClientProportion = formattedLeadToClientProportion ? formattedLeadToClientProportion : '0';
      setEstimatedRevenueSettings({
        ...estimatedRevenueSettings,
        leadToClientProportion: {
          ...estimatedRevenueSettings.leadToClientProportion,
          [leadSource]: parseInt(updatedLeadToClientProportion)
        } 
      });
    }
  }

  const setRevenuePerClient = (revenuePerClient: string, leadSource: LeadSource) => {
    if (estimatedRevenueSettings) {
      const formattedRevenuePerClient = revenuePerClient.replace(/\D/g,'')
      const updatedRevenuePerClient = formattedRevenuePerClient ? formattedRevenuePerClient.replace(/\D/g,'') : '0';
      setEstimatedRevenueSettings({
        ...estimatedRevenueSettings,
        revenuePerClient: {
          ...estimatedRevenueSettings.revenuePerClient,
          [leadSource]: parseInt(updatedRevenuePerClient)
        }
      });
    }
  }

  const updateMetrics = () => {
    if (dashboardBusinessId) {
      setMetrics(null);
      getBusinessMetrics(dashboardBusinessId)
        .then(({ handwrittenLetterMetrics, reactivateLostClientsMetrics, commercialOutreachMetrics}) => 
          setMetrics({ handwrittenLetterMetrics, reactivateLostClientsMetrics, commercialOutreachMetrics }));
    }
    closeSettingsModal();
  }

  if (loading) {
    return (
      <section className={'dashboard-metrics loading'}>
        <LoadingRing color={'#000000'} />
      </section>
    );
  }
  const settingsModalOpen = selectedLeadSource !== null;
  return (
    <section className={'dashboard-metrics'}>
      <DashboardMetricListContainer
        keyId={'living-nearby'}
        metricItems={HandwrittenLetterMetricItems}
        metrics={handwrittenLetterMetrics}
        title={'Living nearby'}
        openSettingsModal={openSettingsModal}
      />
      <DashboardMetricListContainer
        keyId={'working-nearby'}
        metricItems={CommercialOutreachMetricItems}
        metrics={commercialOutreachMetrics}
        title={'Working nearby'}
        openSettingsModal={openSettingsModal}
      />
      <DashboardMetricListContainer
        keyId={'reactivate-lost-clients'}
        metricItems={ReactivateLostClientsMetricItems}
        metrics={reactivateLostClientsMetrics}
        title={'Reactivate lost clients'}
        openSettingsModal={openSettingsModal}
      />
      {settingsModalOpen && (
        <DashboardRevenueEditModal
          modalRef={modalRef}
          close={closeSettingsModal}
          confirm={updateMetrics}
          leadToClientProportion={leadToClientProportionText}
          revenuePerClient={revenuePerClientText}
          setLeadToClientProportion={setLeadToClientProportion}
          setRevenuePerClient={setRevenuePerClient}
          leadSource={selectedLeadSource}
        />
      )}
    </section>
  )
}

export default DashboardMetrics;