import './ChatHistory.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { isSameDay } from '../InboxConstants';

import ChatDatestamp from './chat-components/ChatDatestamp';
import ChatReceiverName from './chat-components/ChatReceiverName';
import ChatMessage from './chat-components/ChatMessage';
import { MessageDirection, MessageHistory } from '../../../reactivate-clients/MessageConstants';

const { Incoming, Outgoing } = MessageDirection;

interface ChatHistoryProps {
  chatHistory: MessageHistory[],
  firstName: string,
  hasMore: boolean,
  page: number,
  setPage: React.Dispatch<React.SetStateAction<number>>
}

function ChatHistory({ chatHistory, firstName, hasMore, page, setPage }: ChatHistoryProps) {
  const observer = useRef<IntersectionObserver | null>(null);
  const chatHistoryRef = useRef<HTMLElement>(null);
  const [prevScrollHeight, setPrevScrollHeight] = useState(0);

  const lastMessageRef = useCallback((node: HTMLDivElement | null) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting) {
          setPage(prevPage => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    }, [setPage]);

  useEffect(() => {
    if (chatHistoryRef.current) {
      setPrevScrollHeight(chatHistoryRef.current.scrollHeight);
      chatHistoryRef.current.scrollTop = page === 0
        ? chatHistoryRef.current.scrollHeight
        : chatHistoryRef.current.scrollHeight - prevScrollHeight;
    }
  }, [chatHistory]);

  if (chatHistory.length > 0) {
    const history = [];
    const chatMessage = chatHistory[0];
    const chatMessageKey =`key-${chatMessage.businessId}-${chatMessage.recipientId}-${chatMessage.creationDate}`;
    // history.push(<ChatDatestamp timestamp={chatMessage.creationDate} key={`timestamp-${chatMessage.creationDate}`}/>);
    // if (chatMessage.direction === Incoming) {
    //   history.push(<ChatReceiverName firstName={firstName} />)
    // }

    // This is the message at the top of the conversation so when the user scrolls to this point, check if there
    // are more messages to load. If there are, put the observer on and load more messages when we reach this point.
    if (chatMessage.message.length > 0 && chatMessage.message.replace(/\s/g, '').length > 0) {
      if (hasMore) {
        history.push(<ChatMessage chatMessage={chatMessage} key={chatMessageKey} lastMessageRef={lastMessageRef} />);
      }
      else {
        history.push(<ChatMessage chatMessage={chatMessage} key={chatMessageKey} />);
      }
    }
    for (let i = 1; i < chatHistory.length; i++) {
      const chatMessage = chatHistory[i];
      const prevChatMessage = chatHistory[i - 1];
      const chatMessageKey =`key-${chatMessage.businessId}-${chatMessage.recipientId}-${chatMessage.creationDate}`;
      if (isSameDay(new Date(chatMessage.creationDate), new Date(prevChatMessage.creationDate))) {
        // if (prevChatMessage.direction === Outgoing && chatMessage.direction === Incoming) {
        //   history.push(<ChatReceiverName firstName={firstName} />)
        // }
        if (chatMessage.message.length > 0 && chatMessage.message.replace(/\s/g, '').length > 0) {
          history.push(<ChatMessage chatMessage={chatMessage} key={chatMessageKey} />);
        }
      }
      else {
        // history.push(<ChatDatestamp timestamp={chatMessage.creationDate} key={`timestamp-${chatMessage.creationDate}`} />);
        if (chatMessage.message.length > 0 && chatMessage.message.replace(/\s/g, '').length > 0) {
          history.push(<ChatMessage chatMessage={chatMessage} key={chatMessageKey} />);
        }
      }
    }
    return <section className={'chat-history'} ref={chatHistoryRef}>{history}</section>;
  } 
  else {
    return (
      <section className={'unavailable-chat-history'}>
        <p className={'unavailable-chat-history-text'}>{'No messages in this conversation! Check back later to see if there are any updates!'}</p>
      </section>
    )
  }
}

export default ChatHistory;