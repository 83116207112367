import '../common/DashboardMetricItem.scss';

import { ReactElement } from 'react';

import InformationIconTooltip from '../../../common/tooltips/InformationIconTooltip';

import { getLastUpdateDate } from '../helpers/MetricHelper';

import UPDATE_ICON from '../assets/last-update-date-icon.svg';
import SETTINGS_ICON from '../assets/settings-icon.svg';
import IconButton from '../../../common/buttons/IconButton';
import { LeadSource } from '../../../../types/LeadTypes';

interface DashboardMetricProps {
  label: string,
  count: number | string,
  icon: string,
  lastUpdateDate: number | null,
  tooltipText: string | null,
  isEditable: boolean | undefined,
  openSettingsModal: ((leadSource: LeadSource) => void) | null,
  leadSource: LeadSource | undefined,
}

function DashboardMetricItem({ label, count, icon, lastUpdateDate, tooltipText, isEditable, openSettingsModal, leadSource } : Readonly<DashboardMetricProps>): ReactElement<DashboardMetricProps> {
  const lastUpdateText = lastUpdateDate ? getLastUpdateDate(lastUpdateDate) : '';
  const selectAndOpenSettingsModal = () => {
    if (openSettingsModal && leadSource) {
      openSettingsModal(leadSource);
    }
  }

  return (
    <section className={'dashboard-metric-item'}>
      <section className={'metric'}>
        <section className={'metric-icon'}>
          <img className={'icon'} src={icon} alt={label} />
        </section>
        <section className={'metric-count'}>
          <section className={'metric-label'}>
            <span className={'metric-text'}>{label}</span>
            { tooltipText && <InformationIconTooltip tooltipText={tooltipText} isFlipped={true} />}
          </section>
          <section className={'metric-count'}>
            {count}
          </section>
        </section>
      </section>
      <section className={`footer ${lastUpdateDate ? 'space-between-icons' : ''}`}>
        { lastUpdateDate && (
          <section className={'last-update-container'}>
            <img className={'update-icon'} src={UPDATE_ICON} alt={'Last updated icon'} />
            <span className={'last-update-text'}>{`Updated ${lastUpdateText}`}</span>
          </section>
        )}
        {isEditable && openSettingsModal && <IconButton additionalClassNames={['dashboard-settings-icon']} icon={SETTINGS_ICON} onClick={selectAndOpenSettingsModal} />}
      </section>
    </section>
  );
}

export default DashboardMetricItem;