import './Link.scss'

import { LinkProps } from './LinkConstants';

function Link({ additionalClassNames = [], href, content } : Readonly<LinkProps>) {
  const classNames = ['link', ...additionalClassNames];
  return (
    <a className={classNames.join(' ')} href={href} target={'_blank'} rel={'noopener noreferrer'}>
      {content}
    </a>
  )
}

export default Link;