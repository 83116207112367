export function isTodaysDate(date: number) {
  const today = new Date();
  const inputDate = new Date(date);
  return inputDate.setHours(0,0,0,0) === today.setHours(0,0,0,0);
};

export function isTomorrowsDate(date: number) {
  const today = new Date();
  const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
  const inputDate = new Date(date);
  return inputDate.setHours(0,0,0,0) === tomorrow.setHours(0,0,0,0);
};

export const formatedDateString = (date: number) => {
  const today = Date.now();
  const isScheduledToday = isTodaysDate(date) && date > today;
  const isScheduledTomorrow = isTomorrowsDate(date);

  const dateToFormat = new Date(date);
  const time = dateToFormat.toLocaleString('en-au', { hour: 'numeric', hour12: true });
  if (isScheduledToday) {
    return `today ${time}`;
  }
  else if (isScheduledTomorrow) {
    return `tomorrow ${time}`;
  }
  else {
    const formattedScheduledDate = date === null ? 'None' : new Date(date).toLocaleDateString('en-au', {
      hour12: true,
      minute: 'numeric',
      hour: 'numeric',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    return formattedScheduledDate;
  }
}

export const nth = (day: number) => {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
};