import './EmptyChatHistory.scss';

import React from 'react';

import LoadingRing from '../../../../common/loading/LoadingRing';

interface EmptyChatHistoryProps {
  loading: boolean,
}

function EmptyChatHistory({ loading } : EmptyChatHistoryProps) {
  return (
    <section className={'unavailable-chat-history'}>
      {loading ? <LoadingRing color={'#000000'} /> : (
        <p className={'unavailable-chat-history-text'}>{'There is no message history yet. Send a message and say hello!'}</p>
      )}
    </section>
  )
}

export default EmptyChatHistory;