import './RecoverClientsEmptyPage.scss';

import { FLATFILE_UPLOAD_TYPE } from './RecoverClientsConstants';

import Dropdown from '../../common/dropdowns/Dropdown';
import { ReactElement } from 'react';

interface RecoverClientsEmptyPageProps {
  flatFileUploadType: string,
  importButton: ReactElement,
  setFlatFileUploadType: React.Dispatch<React.SetStateAction<string>>
}

function RecoverClientsEmptyPage({ flatFileUploadType, importButton, setFlatFileUploadType }: RecoverClientsEmptyPageProps) {
  return (
    <section className={'recover-clients-button-dashboard'}>
      <h4 className={'subtitle'}>{'No lost clients uploaded just yet, check back later or start upload your files to get started.'}</h4>
      <section className={'fine-tune-message-buttons'}>
        <Dropdown defaultDisplayText={'Upload file'} options={FLATFILE_UPLOAD_TYPE} select={setFlatFileUploadType} selected={flatFileUploadType} />
        {flatFileUploadType && importButton}
      </section>
    </section>
  )
}

export default RecoverClientsEmptyPage;