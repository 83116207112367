import { useEffect, useState } from 'react';

import { InboxRecipientWithLatestMessage } from '../../../people/PeopleConstants';
import { getBusinessRecipientConversations, getRecipientDraftMessage, postFindInboxRecipients } from '../../../../apis/MessageApi';
import { MessageHistory } from '../../reactivate-clients/MessageConstants';

interface BusinessRecipientsInboxListData {
  recipients: InboxRecipientWithLatestMessage[] | [],
  setRecipients: (recipients: InboxRecipientWithLatestMessage[] | null) => void,
  loading: boolean,
  page: number,
  setPage: React.Dispatch<React.SetStateAction<number>>,
  searchText: string,
  setSearchText: (searchText: string) => void,
  showOnlyUnread: boolean,
  setShowOnlyUnread: React.Dispatch<React.SetStateAction<boolean>>,
  hasMore: boolean,
};

interface BusinessRecipientsInboxConversationData {
  messages: MessageHistory[] | [],
  setMessages: React.Dispatch<React.SetStateAction<MessageHistory[] | null>>,
  page: number,
  setPage: React.Dispatch<React.SetStateAction<number>>,
  hasMore: boolean,
  loading: boolean,
};

interface RecipientDraftMessageData {
  draftMessage: string,
  setDraftMessage: React.Dispatch<React.SetStateAction<string>>,
};

export function useInboxRecipientList(businessId: string | null, navigatedSearchText: string): BusinessRecipientsInboxListData {
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [showOnlyUnread, setShowOnlyUnread] = useState(false);
  const [recipients, setRecipients] = useState<InboxRecipientWithLatestMessage[] | null>(null);
  const [hasMore, setHasMore] = useState(false);
  const [oldBusinessId, setOldBusinessId] = useState<string | null>(null);

  useEffect(() => {
    if (businessId && oldBusinessId !== businessId) {
      setPage(0);
      setSearchText('');
      setRecipients(null);
      setShowOnlyUnread(false);
      setOldBusinessId(businessId);
    }
  }, [businessId]);

  useEffect(() => {
    if (navigatedSearchText) {
      setSearchText(navigatedSearchText);
    }
  }, [navigatedSearchText])
  
  useEffect(() => {
    if (businessId) {
      const currentRecipients = recipients !== null ? recipients : [];
      postFindInboxRecipients(businessId, searchText, page, showOnlyUnread).then((response) => {
        console.log(response);
        setRecipients([ ...currentRecipients, ...response.recipients ]);
        setHasMore(response.hasMore);
      });
    }
  }, [oldBusinessId, page, searchText, showOnlyUnread]);

  return {
    recipients: recipients || [],
    setRecipients,
    loading: recipients === null,
    page,
    setPage,
    searchText,
    setSearchText,
    showOnlyUnread,
    setShowOnlyUnread,
    hasMore,
  };
}

export function useRecipientConversation(businessId: string | null, recipientId: string | null): BusinessRecipientsInboxConversationData {
  const [messages, setMessages] = useState<MessageHistory[] | null>(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [oldBusinessId, setOldBusinessId] = useState<string | null>(null);
  const [oldRecipientId, setOldRecipientId] = useState<string | null>(null);
  
  useEffect(() => {
    if (businessId && recipientId) {
      const businessOrRecipientChange = oldBusinessId !== businessId || oldRecipientId !== recipientId;
      if (businessOrRecipientChange) {
        setMessages(null);
        setPage(0);
        setOldBusinessId(businessId);
        setOldRecipientId(recipientId);
      }
      const currentMessages = messages !== null && !businessOrRecipientChange ? messages : [];
      const pageLoad = businessOrRecipientChange ? 0 : page;
      getBusinessRecipientConversations(businessId, recipientId, pageLoad)
        .then((response) => {
          setMessages([ ...currentMessages, ...response.messages ]);
          setHasMore(response.hasMore);
        });
    }
  }, [businessId, recipientId, page]);

  return {
    messages: messages || [],
    setMessages,
    loading: messages === null,
    page,
    setPage,
    hasMore,
  };
}

export function useConversationDraftMessage(recipientId: string | null): RecipientDraftMessageData {
  const [draftMessage, setDraftMessage] = useState<string>('');
  
  useEffect(() => {
    if (recipientId) {
      getRecipientDraftMessage(recipientId)
        .then((response) => {
          setDraftMessage(response.draftMessage);
        });
    }
  }, [recipientId]);

  return {
    draftMessage,
    setDraftMessage,
  };
}