import './ChatMessage.scss';

import { MessageDirection, MessageHistory } from '../../../../reactivate-clients/MessageConstants';

import ChatTimestamp from './ChatTimestamp';

const { Incoming, Outgoing } = MessageDirection;

interface ChatMessageProps {
  chatMessage: MessageHistory,
  lastMessageRef?: (node: HTMLDivElement | null) => void
}

function ChatMessage ({ chatMessage, lastMessageRef }: ChatMessageProps) {
  const { message, creationDate, direction } = chatMessage;

  return (
    <div className={'chat-msg-container'} ref={lastMessageRef}>
      <div className={'chat-msg-body'}>
        <span className={`chat-message ${direction === Incoming ? 'receiver' : 'sender'}`}>
          {message}
        </span>
        <ChatTimestamp 
          timestamp={creationDate}
          isReceiver={direction === Outgoing}
          key={`timestamp-${creationDate}`}
        />
      </div>
    </div>
  );
}

export default ChatMessage;