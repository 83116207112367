import './LoggedOutPage.scss';

import { useState, useEffect } from 'react';
import { Outlet } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { LeanBusiness } from '../../types/BusinessTypes';
import NavigationSidebar from '../navigation/sidebar/NavigationSidebar';

import { LOGGED_OUT_NAVIGATION_ITEMS } from '../navigation/sidebar/NavigationSidebarConstants';

import LoadingRingPage from '../common/pages/LoadingRingPage';
import { getLeanBusiness } from '../../apis/BusinessApi';

function LoggedOutPage() {
  const [business, setBusiness] = useState<LeanBusiness | null>(null);
  const [searchParams] = useSearchParams();
  const selectedBusinessId = searchParams.get('business_id') || '';
  useEffect(() => {
    if (selectedBusinessId) {
      getLeanBusiness(selectedBusinessId).then((response) => setBusiness(response.business));
    }
  }, [selectedBusinessId]);
  if (!business) {
    return <LoadingRingPage color={'#000000'} />;
  }
  const businessOptions = [{ displayText: business.businessName, value: selectedBusinessId }];
  return (
    <main className={'work-rex-page'}>
      { /** This element is used for portaling: https://ankitjoshi.hashnode.dev/react-portal-and-tooltip */}
      <section id={'portal-root'} />
      <NavigationSidebar sidebarNavigationItems={LOGGED_OUT_NAVIGATION_ITEMS} selectedBusinessId={selectedBusinessId} businessOptions={businessOptions} isLoggedOut={true} />
      <section className={'sidebar-buffer-container'}>
        <Outlet />
      </section>
    </main>
  )
}

export default LoggedOutPage;