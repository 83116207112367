import './LeadConversation.scss';

import { InboxRecipientWithLatestMessage } from '../../../people/PeopleConstants';

import ChatRoom from './inbox/ChatRoom';
import ChatRecipientDetails from './inbox/ChatRecipientDetails';

interface LeadConversationProps {
  selectedRecipient: InboxRecipientWithLatestMessage,
}

function LeadConversation({ selectedRecipient } : LeadConversationProps) {

  return (
    <section className={'lead-conversation'}>
      <section className={'lead-conversation-header'}>
        <ChatRecipientDetails recipient={selectedRecipient}  />
      </section>
      <ChatRoom recipient={selectedRecipient}/>
    </section>
  )
}

export default LeadConversation;