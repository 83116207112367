import './ChatRoom.scss';

import useAuth from '../../../../../context/AuthContext';

import { InboxRecipientWithLatestMessage } from '../../../../people/PeopleConstants';

import EmptyChatHistory from './EmptyChatHistory';
import ChatInput from './ChatInput';
import ChatHistory from './ChatHistory';

import { useRecipientConversation } from '../../hooks/InboxHooks';

interface ChatRoomProps {
  recipient: InboxRecipientWithLatestMessage,
}

function ChatRoom({ recipient } : ChatRoomProps) {
  const { selectedBusinessId } = useAuth();
  const { messages, setMessages, loading, hasMore, page, setPage } = useRecipientConversation(selectedBusinessId, recipient._id);

  const sortedChatHistory = messages.sort((a, b) => a.creationDate - b.creationDate);
  const hasValidDetails = selectedBusinessId && recipient;

  const showChatHistory = !!messages && messages.length > 0 && hasValidDetails;
  const showEmptyChatHistory = messages && messages.length === 0;
  return (
    <section className={'chat-room'}>
      {showChatHistory && (
        <ChatHistory
          chatHistory={sortedChatHistory}
          firstName={recipient.firstName}
          hasMore={hasMore}
          page={page}
          setPage={setPage}
        />
      )}
      {showEmptyChatHistory && <EmptyChatHistory loading={loading} />}
      <ChatInput recipient={recipient} setMessages={setMessages} />
    </section>
  )
}

export default ChatRoom;