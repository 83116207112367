import './BlackTag.scss';

import { TagProps } from './TagConstants';

import Tag from './Tag';

function BlackTag({ content, additionalClassNames = [] }: Readonly<TagProps>) {
  return <Tag content={content} additionalClassNames={['black', ...additionalClassNames]} />;
};

export default BlackTag;