export enum MessageStatus {
  Delivered = 'Delivered',
  NotSent = 'Not sent',
};

export enum Channel {
  SMS = 'SMS',
  Email = 'Email'
}

export enum RecipientType {
  Lead = 'Lead',
  Client = 'Client',
}

export enum MessageDirection {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
}

export enum MessageSource {
  LostClient = 'LostClient',
  Email = 'Email',
  LeadNurture = 'LeadNurture',
  Inbox = 'Inbox'
};

export enum MessageChannel {
  SMS = 'SMS',
  EMAIL = 'Email',
  LINKED_IN = 'LinkedIn'
};

export interface Message {
  _id: string,
  recipientName: string,
  sendTo: string,
  messageStatus: MessageStatus,
  sendDate: number | null,
  channel: Channel,
  message: string,
  recipientType: RecipientType,
}

export interface MessageHistory {
  message: string,
  businessId: string,
  creationDate: number,
  readDate: number | null,
  direction: MessageDirection,
  recipientId: string,
  channel: MessageChannel,
  lastMessageReminderDate: number | null,
  source: MessageSource,
};