
import './UsageAndBillingPage.scss';

import { ReactElement } from 'react';

import useAuth from '../../../../context/AuthContext';
import LoadingRing from '../../../common/loading/LoadingRing';

import { useBusinessEvents, useBusinessSubscription } from '../hooks/AccountHooks';

import AccountActivityLog from '../common/AccountActivityLog';
import PlanDetails from '../common/PlanDetails';
import CreditDetails from '../common/CreditDetails';

function UsageAndBillingPage(): ReactElement {
  const { selectedBusinessId } = useAuth();
  const { events, loading } = useBusinessEvents(selectedBusinessId);
  const subscriptionData = useBusinessSubscription(selectedBusinessId);
  const usedCredits = subscriptionData?.subscription?.usedCredits || 0;
  const totalCredits = subscriptionData?.subscription?.totalCredits || 0;
  const subscriptionLoading = subscriptionData.loading;
  const isEmpty = !loading && events.length === 0;
  const accountActivityLog = loading ? <LoadingRing color={'#000000'} /> : <AccountActivityLog activities={events} isEmpty={isEmpty} />;
  return (
    <article className={'usage-and-billing-page'}>
      <section className={'page'}>
        <header className={'usage-and-billing-header'}>
          <h2 className={'title'}>{'Usage and billing'}</h2>
          <h3 className={'subtitle'}>{'Overview of your account'}</h3>
        </header>
        <section className={'billing-details'}>
          { !subscriptionLoading && <PlanDetails plan={subscriptionData.subscription.plan} /> }
          { !subscriptionLoading && <CreditDetails usedCredits={usedCredits} totalCredits={totalCredits} /> }
        </section>
        <section className={`loading-page-content ${loading ? 'loading' : ''}`}>
          {accountActivityLog}
        </section>
      </section>
    </article>
  );
}

export default UsageAndBillingPage;