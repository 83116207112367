import './ChatInput.scss';

import { useEffect, useMemo, useState } from 'react';

import useAuth from '../../../../../context/AuthContext';

import { Deliverability, InboxRecipientWithLatestMessage } from '../../../../people/PeopleConstants';
import { determineMessageChannel, generateMessageHistory } from '../../helpers/InboxHelpers';
import { postSaveDraft, postSendMessage } from '../../../../../apis/MessageApi';
import { MessageHistory } from '../../../reactivate-clients/MessageConstants';

import PrimaryButton from '../../../../common/buttons/PrimaryButton';
import TextAreaInput from '../../../../common/inputs/TextAreaInput';
import { debounce } from 'lodash';
import { useConversationDraftMessage } from '../../hooks/InboxHooks';

interface ChatInputProps {
  recipient: InboxRecipientWithLatestMessage,
  setMessages: React.Dispatch<React.SetStateAction<MessageHistory[] | null>>,
}

function ChatInput({ recipient, setMessages }: ChatInputProps) {
  const { selectedBusinessId } = useAuth();
  const { draftMessage, setDraftMessage } = useConversationDraftMessage(recipient._id);
  const [text, setText] = useState(draftMessage);
  const disableSend = text.trim().length === 0;
  const invalidRecipient = recipient.deliverability === Deliverability.OPTED_OUT || (!recipient.mobileNumber && !recipient.conversationId);
  const lastMessageChannel = recipient?.latestMessage?.channel ? recipient?.latestMessage?.channel : determineMessageChannel(recipient);

  const sendMessage = async () => {
    if (!invalidRecipient && !disableSend && selectedBusinessId && lastMessageChannel) {
      const newMessageHistory = generateMessageHistory(recipient, selectedBusinessId, text, lastMessageChannel);
      const response = await postSendMessage(text, lastMessageChannel, recipient._id);
      if (response.success) {
        setMessages(prevMessageHistory => {
          const messageHistory = prevMessageHistory ? prevMessageHistory : [];
          return [ ...messageHistory, newMessageHistory ];
        });
        setText('');
        await postSaveDraft(recipient._id, '');
      }
      else {
        // TODO: Add error text.
      }
    }
  }

  useEffect(() => {
    setText(draftMessage);
  }, [recipient, draftMessage])

  const debouncedSaveDraft = useMemo(
      () =>
        debounce(async (recipientId: string, value: string) => {
          await postSaveDraft(recipientId, value);
        }, 500), // 500ms debounce delay
      []
    );
  
  const saveDraft = (value: string) => {
    setText(value);
    setDraftMessage(value);
    debouncedSaveDraft(recipient._id, value);
  }

  const placeHolderText = invalidRecipient
    ? 'There is no valid contact method setup for this recipient, or this recipient has opted out of messaging.'
    : 'Write a message';

  return (
    <section className={'chat-input-container'}>
      <section className={'chat-bar'}>
        <section className={'inputs'}>
          <TextAreaInput
            text={text}
            setText={saveDraft}
            placeholder={placeHolderText}
            disabled={invalidRecipient}
            rows={4}
          />
        </section>
        <section className={'chat-input-buttons'}>
          <PrimaryButton content={'Send'} disabled={disableSend || invalidRecipient} onClick={sendMessage} />
        </section>
      </section>
    </section>
  );
};

export default ChatInput;
