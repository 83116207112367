import { useEffect } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  children: React.ReactNode;
}

function Portal({ children }: PortalProps) {
  const portal = document.getElementById('portal-root');
  const el = document.createElement('section');

  useEffect(() => {
    if (portal) {
      portal.appendChild(el);
    }
    return () => {
      if (portal) {
        portal.removeChild(el)
      };
    }
  }, [el, portal]);

  return createPortal(children, el);
}

export { Portal };