/**
 * Checks a is equal to b, case insensitive.
 * @param {String} a 
 * @param {String} b 
 * @returns 
 */
export const stringEquals = (a: any, b: any) => {
  return !!a && !!b && a.toLowerCase() === b.toLowerCase();
}

/**
 * Checks a is not equal to b, case insensitive.
 * @param {String} a 
 * @param {String} b 
 * @returns 
 */
export const stringNotEquals = (a: any, b: any) => {
  return !a || !b || a.toLowerCase() !== b.toLowerCase();
}

/**
 * Checks a is equal to b.
 * @param {Integer} a 
 * @param {Integer} b 
 * @returns 
 */
export const numberEquals = (a: any, b: any) => {
  return parseInt(a) === parseInt(b);
}

/**
 * Checks a is not equal to b.
 * @param {Integer} a 
 * @param {Integer} b 
 * @returns 
 */
export const numberNotEquals = (a: any, b: any) => {
  return parseInt(a) !== parseInt(b);
}

/**
 * Checks a is equal to b.
 * @param {Integer} a 
 * @param {Integer} b 
 * @returns 
 */
export const dateEquals = (a: any, b: any) => {
  if (a && b) {
    const recordDate = new Date(parseInt(a));
    const filterDate = new Date(parseInt(b));
    return recordDate.setHours(0,0,0,0) === filterDate.setHours(0,0,0,0);
  }
  return false;
}

/**
 * Checks a is not equal to b.
 * @param {Integer} a 
 * @param {Integer} b 
 * @returns 
 */
export const dateNotEquals = (a: any, b: any) => {
  if (a && b) {
    const recordDate = new Date(parseInt(a));
    const filterDate = new Date(parseInt(b));
    return recordDate.setHours(0,0,0,0) !== filterDate.setHours(0,0,0,0);
  }
  return false;
}

/**
 * Checks a is less than b.
 * @param {Integer} a 
 * @param {Integer} b 
 * @returns 
 */
export const lessThan = (a: any, b: any) => {
  return parseInt(a) < parseInt(b);
}

/**
 * Checks a is greater than b.
 * @param {Integer} a 
 * @param {Integer} b 
 * @returns 
 */
export const greaterThan = (a: any, b: any) => {
  return parseInt(a) > parseInt(b);
}

/**
 * Checks if b is a substring of a.
 * @param {String} a 
 * @param {String} b 
 * @returns 
 */
export const contains = (a: any, b: any) => {
  return !!a && !!b && a.toLowerCase().includes(b.toLowerCase());
}

/**
 * Checks if b is a substring of a.
 * @param {String} a 
 * @param {String} b 
 * @returns 
 */
export const isOneOf = (a: any, b: any) => {
  return !!a && !!b && b.key === a;
}

/**
 * Checks if any value in array a exists in array b and returns true if it does.
 * @param {Array} a 
 * @param {Array} b 
 * @returns 
 */
export const arrayContains = (a: any, b: any) => {
  return !!a && !!b && b.some((bVal: any) => a.some((aVal : any) => aVal.includes(bVal)));
}

/**
 * Checks if b is not a substring of a.
 * @param {String} a 
 * @param {String} b 
 * @returns 
 */
export const notContains = (a: any, b: any) => {
  return !a || !b || !a.toLowerCase().includes(b.toLowerCase());
}

/**
 * Checks if any value in array b exists in array a and returns false if it does.
 * @param {Array} a 
 * @param {Array} b 
 * @returns 
 */
export const arrayNotContains = (a: any, b: any) => {
  return !a || !b || b.every((bVal: any) => a.every((aVal : any) => !aVal.includes(bVal)));
}

/**
 * Checks if a equates to false or has length === 0.
 * @param {*} a 
 * @returns 
 */
export const isEmpty = (a: any) => {
  return !!a === false || (a && a.length === 0);
}

/**
 * Checks if a equates to true or has length > 0.
 * @param {*} a 
 * @returns 
 */
export const isNotEmpty = (a: any) => {
  return !!a === true || (a && a.length > 0);
}

/**
 * Checks if b exists in a where a is an array.
 * @param {Array} a 
 * @param {*} b 
 * @returns 
 */
export const inMonth = (a: any, b: any) => {
  return a === b;
}

/**
 * Checks a is equal to b.
 * @param {any} a 
 * @param {any} b 
 * @returns 
 */
export const valueEquals = (a: any, b: any) => {
  return a === b;
}

/**
 * Checks a is not equal to b.
 * @param {any} a 
 * @param {any} b 
 * @returns 
 */
export const valueNotEquals = (a: any, b: any) => {
  return a !== b;
}

/**
 * Checks a is empty.
 * @param {any} a 
 * @returns 
 */
export const valueEmpty = (a: any) => {
  return a === undefined || a === null;
}

/**
 * Checks a is not empty.
 * @param {any} a 
 * @param {any} b 
 * @returns 
 */
export const valueNotEmpty= (a: any) => {
  return a !== undefined && a !== null;
}