import {
  numberEquals,
  numberNotEquals,
  lessThan,
  greaterThan,
  stringEquals,
  stringNotEquals,
  contains,
  notContains,
  dateEquals,
  dateNotEquals,
  isEmpty,
  isNotEmpty,
  valueEquals,
  valueNotEquals,
  valueEmpty,
  valueNotEmpty,
} from './helpers/FilterFunctions';

import {
  ConditionKey,
  ConditionOptionsMapType,
  ConditionText,
  FilterConditionMapType,
  FilterType
} from './types/FilterTypes';

const {
  IsExactlyKey,
  IsExactlyNotKey,
  ContainsKey,
  DoesNotContainKey,
  IsEmptyKey,
  IsNotEmptyKey,
  IsGreaterThanKey,
  IsLessThanKey,
  IsEqualKey,
  IsNotEqualKey,
} = ConditionKey;

const {
  IsExactly,
  IsExactlyNot,
  Contains,
  DoesNotContain,
  IsEmpty,
  IsNotEmpty,
  IsGreaterThan,
  IsLessThan,
  IsEqual,
  IsNotEqual,
} = ConditionText;


/** 
 * This provides a mapping from a filter type and selected condition to the function that can used to apply that condition.
 */
export const FilterConditionMap: FilterConditionMapType = {
  [FilterType.NUMBER]: {
    isEqual: {
      key: IsEqualKey,
      displayText: 'Equal to',
      valueType: FilterType.NUMBER,
      valueTypeTitle: 'isEqual',
      filterFunction: numberEquals
    },
    isNotEqual: {
      key: IsNotEqualKey,
      displayText: 'Not Equal to',
      valueType: FilterType.NUMBER,
      valueTypeTitle: 'isNotEqual',
      filterFunction: numberNotEquals
    },
    isLessThan: {
      key: IsLessThanKey,
      displayText: 'Is Less Than',
      valueType: FilterType.NUMBER,
      valueTypeTitle: 'isLessThan',
      filterFunction: lessThan
    },
    isGreaterThan: {
      key: IsGreaterThanKey,
      displayText: 'Is Greater Than',
      valueType: FilterType.NUMBER,
      valueTypeTitle: 'isGreaterThan',
      filterFunction: greaterThan
    },
    isEmpty: {
      key: IsEmptyKey,
      displayText: 'Is empty',
      valueType: FilterType.NUMBER,
      valueTypeTitle: 'empty',
      filterFunction: isEmpty
    },
    isNotEmpty: {
      key: IsNotEmptyKey,
      displayText: 'Is not empty',
      valueType: FilterType.NUMBER,
      valueTypeTitle: 'notEmpty',
      filterFunction: isNotEmpty
    },
  },
  [FilterType.STRING]: {
    isExactly: {
      key: IsExactlyKey,
      displayText: 'Is exactly',
      valueType: FilterType.STRING,
      valueTypeTitle: 'isExactly',
      filterFunction: stringEquals
    },
    isExactlyNot: {
      key: IsExactlyNotKey,
      displayText: 'Is exactly not',
      valueType: FilterType.STRING,
      valueTypeTitle: 'isExactlyNot',
      filterFunction: stringNotEquals
    },
    contains: {
      key: ContainsKey,
      displayText: 'Contains',
      valueType: FilterType.STRING,
      valueTypeTitle: 'contains',
      filterFunction: contains
    },
    doesNotContain: {
      key: DoesNotContainKey,
      displayText: 'Does not contain',
      valueType: FilterType.STRING,
      valueTypeTitle: 'doesNotContain',
      filterFunction: notContains
    },
    isEmpty: {
      key: IsEmptyKey,
      displayText: 'Is empty',
      valueType: FilterType.STRING,
      valueTypeTitle: 'isEmpty',
      filterFunction: isEmpty
    },
    isNotEmpty: {
      key: IsNotEmptyKey,
      displayText: 'Is not empty',
      valueType: FilterType.STRING,
      valueTypeTitle: 'isNotEmpty',
      filterFunction: isNotEmpty
    },
  },
  [FilterType.DATE]: {
    isEqual: {
      key: IsEqualKey,
      displayText: 'Is equal to',
      valueType: FilterType.DATE,
      valueTypeTitle: 'isEqual',
      filterFunction: dateEquals
    },
    isNotEqual: {
      key: IsNotEqualKey,
      displayText: 'Not equal to',
      valueType: FilterType.DATE,
      valueTypeTitle: 'isNotEqual',
      filterFunction: dateNotEquals
    },
    isLessThan: {
      key: IsLessThanKey,
      displayText: 'Is Before',
      valueType: FilterType.DATE,
      valueTypeTitle: 'isLessThan',
      filterFunction: lessThan
    },
    isGreaterThan: {
      key: IsGreaterThanKey,
      displayText: 'Is After',
      valueType: FilterType.DATE,
      valueTypeTitle: 'isGreaterThan',
      filterFunction: greaterThan
    },
    isEmpty: {
      key: IsEmptyKey,
      displayText: 'Is empty',
      valueType: FilterType.DATE,
      valueTypeTitle: 'isEmpty',
      filterFunction: isEmpty
    },
    isNotEmpty: {
      key: IsNotEmptyKey,
      displayText: 'Is not empty',
      valueType: FilterType.DATE,
      valueTypeTitle: 'isNotEmpty',
      filterFunction: isNotEmpty
    },
  },
  [FilterType.DROPDOWN]: {
    isEqual: {
      key: IsEqualKey,
      displayText: 'Is equal to',
      valueType: FilterType.DROPDOWN,
      valueTypeTitle: 'isEqual',
      filterFunction: valueEquals
    },
    isNotEqual: {
      key: IsNotEqualKey,
      displayText: 'Not equal to',
      valueType: FilterType.DROPDOWN,
      valueTypeTitle: 'isNotEqual',
      filterFunction: valueNotEquals
    },
    isEmpty: {
      key: IsEmptyKey,
      displayText: 'Is empty',
      valueType: FilterType.DROPDOWN,
      valueTypeTitle: 'isEmpty',
      filterFunction: valueEmpty,
    },
    isNotEmpty: {
      key: IsNotEmptyKey,
      displayText: 'Is not empty',
      valueType: FilterType.DROPDOWN,
      valueTypeTitle: 'isNotEmpty',
      filterFunction: valueNotEmpty,
    },
  },
}

/**
 * Options for each condition.
 * Each filter type has a set of conditions that can be applied via predicate function.
 * This provides the list of options given a filter type.
 */
export const ConditionOptionsMap : ConditionOptionsMapType = {
  [FilterType.STRING]: [
    { displayText: IsExactly, value: IsExactlyKey },
    { displayText: IsExactlyNot, value: IsExactlyNotKey },
    { displayText: Contains, value: ContainsKey },
    { displayText: DoesNotContain, value: DoesNotContainKey },
    { displayText: IsEmpty, value: IsEmptyKey },
    { displayText: IsNotEmpty, value: IsNotEmptyKey },
  ],
  [FilterType.NUMBER]: [
    { displayText: IsExactly, value: IsExactlyKey },
    { displayText: IsExactlyNot, value: IsExactlyNotKey },
    { displayText: IsGreaterThan, value: IsGreaterThanKey },
    { displayText: IsLessThan, value: IsLessThanKey },
    { displayText: IsEmpty, value: IsEmptyKey },
    { displayText: IsNotEmpty, value: IsNotEmptyKey },
  ],
  [FilterType.DATE]: [
    { displayText: IsEqual, value: IsEqualKey },
    { displayText: IsNotEqual, value: IsNotEqualKey },
    { displayText: IsGreaterThan, value: IsGreaterThanKey },
    { displayText: IsLessThan, value: IsLessThanKey },
    { displayText: IsEmpty, value: IsEmptyKey },
    { displayText: IsNotEmpty, value: IsNotEmptyKey },
  ],
  [FilterType.DROPDOWN]: [
    { displayText: IsEqual, value: IsEqualKey },
    { displayText: IsNotEqual, value: IsNotEqualKey },
    { displayText: IsEmpty, value: IsEmptyKey },
    { displayText: IsNotEmpty, value: IsNotEmptyKey },
  ],
};

export const EmptyFilter = {
  attribute: null,
  condition: null,
  input: '',
};