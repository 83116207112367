import './CommercialOutreachTable.scss';

import { useState } from 'react';

import useAuth from '../../../../context/AuthContext';
import { ModalAction } from '../../../people/PeopleConstants';

import ProgressLevels from '../../../common/bars/ProgressLevels';
import InformationIconTooltip from '../../../common/tooltips/InformationIconTooltip';
import PaginationBar from '../../../common/tables/PaginationBar';
import DeleteMessageModal from '../../reactivate-clients/modals/DeleteMessageModal';
import TrashIconButton from '../../../common/buttons/composite/TrashIconButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import GreenTag from '../../../common/tags/GreenTag';
import DarkPurpleTag from '../../../common/tags/DarkPurpleTag';
import LightPurpleTag from '../../../common/tags/LightPurpleTag';
import RoundedLetterTag from '../../../common/tags/RoundedLetterTag';
import ConfirmMessageSendModal from '../../reactivate-clients/modals/SendMessageModal';
import Table, { TableHeaderProps } from '../../../common/tables/Table';

import { getProximityText } from '../../living-nearby/helpers/DistanceHelpers';
import { CommercialOutreachStatus, LinkedInUser } from '../CommercialOutreachConstants';
import { deleteManyLinkedInUsers, sendManyLinkedInUsers } from '../../../../apis/CommercialOutreachApi';
import CharacteristicsNode from './CharacteristicsNode';

const COMMERCIAL_OUTREACH_TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'First name', key: 'firstName' },
  { displayText: 'Last name', key: 'lastName' },
  { displayText: 'Characteristics', key: 'characteristics' },
  { displayText: 'Company', key: 'companyName' },
  { displayText: 'Proximity', key: 'proximity' },
  { displayText: 'Outreach status', key: 'status' },
  { displayText: 'LinkedIn URL', key: 'linkedInUrl' },
  { displayText: 'Email', key: 'emailAddress' },
  { displayText: 'Send request', key: 'send' },
  { displayText: 'Delete', key: 'delete' },
];

const RECIPIENTS_MAX_ROWS = 5;
const TOTAL_DISTANCE_LEVELS = 4;

const getStatusNode = (status: CommercialOutreachStatus) => {
  switch (status) {
    case CommercialOutreachStatus.Available:
      return <GreenTag content={status} additionalClassNames={['tag-cell']} />;
    case CommercialOutreachStatus.Requested:
      return <LightPurpleTag content={status} additionalClassNames={['tag-cell']} />;
    case CommercialOutreachStatus.Complete:
      return <DarkPurpleTag content={status} additionalClassNames={['tag-cell']} />;
    default:
      return null;
  }
};

interface CommercialOutreachTableProps {
  linkedInUsers: LinkedInUser[],
  setLinkedInUsers: (linkedInUsers: LinkedInUser[]) => void,
  selectedLinkedInUsers: LinkedInUser[],
  setSelectedLinkedInUsers: React.Dispatch<React.SetStateAction<LinkedInUser[]>>,
  cursor: number,
  setCursor: React.Dispatch<React.SetStateAction<number>>,
  hasMessagesToSend: boolean,
}

const { Delete, Send } = ModalAction;

function CommercialOutreachTable({ selectedLinkedInUsers, setSelectedLinkedInUsers, linkedInUsers = [], setLinkedInUsers, cursor, setCursor, hasMessagesToSend } : CommercialOutreachTableProps) {
  const { selectedBusinessId, business, setShowPricingTable } = useAuth();
  const [selectedLinkedInUserId, setSelectedLinkedInUserId] = useState<string | null>(null);
  const [modalAction, setModalAction] = useState<ModalAction | null>(null);
  const selectAllRows = () => {
    if (selectedLinkedInUsers.length === linkedInUsers.length) {
      setSelectedLinkedInUsers([]);
    }
    else {
      setSelectedLinkedInUsers(linkedInUsers);
    }
  };
  const headers = [
    ...COMMERCIAL_OUTREACH_TABLE_HEADERS
  ]

  const hasHeyReachSettings = business && business.heyReachSettings ? (!!business.heyReachSettings.linkedInAccountId && !!business.heyReachSettings.primaryCampaignId) : false;

  const rows = linkedInUsers
    .map((linkedInUser) => {
      const { _id, firstName, lastName, companyName, addressLocation, status, linkedInUrl, emailAddress, approximateAge, approximateGender, isWearingGlasses, skinTone } = linkedInUser;
      const isSelected = !!selectedLinkedInUsers.find((row) => row._id === _id);
      const characteristicsNode = (
        <span className={'two-cell short-text'}>
          <CharacteristicsNode approximateAge={approximateAge} approximateGender={approximateGender} isWearingGlasses={isWearingGlasses} skinTone={skinTone} />
        </span>
      );
      const selectRow = () => {
        if (isSelected) {
          const filteredRows = selectedLinkedInUsers.filter((row) => row._id !== _id);
          setSelectedLinkedInUsers(filteredRows);
        }
        else {
          setSelectedLinkedInUsers([ linkedInUser, ...selectedLinkedInUsers ]);
        }
      }
      const firstNameNode = <section className={'two-cell short-text'} >{firstName ? firstName.trim() : firstName}</section>
      const lastNameNode = <section className={'two-cell short-text'} >{lastName ? lastName.trim() : lastName}</section>
      const companyNameNode = <section className={'two-cell short-text'} >{companyName ? companyName.trim() : companyName}</section>
      const proximity = getProximityText(business?.businessLocation, addressLocation);
      const proximityNode = proximity ? (
        <section className={'proximity-cell'}>
          <ProgressLevels filled={proximity.level} total={TOTAL_DISTANCE_LEVELS} />
          <InformationIconTooltip tooltipText={proximity.tooltipText} />
        </section>
      ) : (
        <section className={'proximity-cell'}>
          {'No data found'}
        </section>
      );
      const statusNode = <section className={'one-button-cell short-text'} >{getStatusNode(status)}</section>
      const linkedInUrlNode = linkedInUrl ? (
        <section className={'two-cell short-text linked-in-url-cell'}>
          <RoundedLetterTag content={firstName.charAt(0).toUpperCase()} />
          <a className={'linked-in-url-tag'} href={linkedInUrl} target={'_blank'} rel={'noopener noreferrer'}>{`${firstName}'s LinkedIn`}</a>
        </section>
      ) : (
        <section className={'proximity-cell'}>
          {'No data found'}
        </section>
      );
      const emailAddressNode = <section className={'two-cell short-text'} >{emailAddress.trim()}</section>

      const openModal = (modalAction: ModalAction) => {
        setSelectedLinkedInUserId(_id);
        setModalAction(modalAction);
      }

      const isAvailable = status === CommercialOutreachStatus.Available && hasHeyReachSettings;
      const sendNode = <section className={'one-button-cell'}><PrimaryButton onClick={() => openModal(Send)} content={'Send'} disabled={!isAvailable} additionalClassNames={['tag-cell']} /></section>;
      const deleteNode = <TrashIconButton onClick={() => openModal(Delete)} disabled={!isAvailable} additionalClassNames={['one-cell']} />;
      return {
        cells: [
          { key: 'firstName', value: firstNameNode },
          { key: 'lastName', value: lastNameNode },
          { key: 'characteristics', value: characteristicsNode },
          { key: 'companyName', value: companyNameNode },
          { key: 'proximity', value: proximityNode },
          { key: 'status', value: statusNode },
          { key: 'linkedInUrlNode', value: linkedInUrlNode },
          { key: 'emailAddressNode', value: emailAddressNode },
          { key: 'send', value: sendNode },
          { key: 'delete', value: deleteNode },
        ],
        selected: isSelected,
        isSelectorDisabled: !isAvailable,
        selectRow,
      }
    });

  if (rows === null || rows === undefined || rows.length === 0) {
    return null;
  }

  // Display Text Modal for editing recipient fields.
  const modalOpen = selectedLinkedInUserId !== null;
  const closeConfirmationModal = () => setSelectedLinkedInUserId(null);

  // Send Recover Client Message Modal
  const sendRecoverClientMessageModalOpen = modalOpen && modalAction === Send;
  const confirmSendRecoverClientMessage = async (setLoading: (loading: boolean) => void) => {
    if (selectedBusinessId && selectedLinkedInUserId) {
      setLoading(true);
      const { success, overcost } = await sendManyLinkedInUsers(selectedBusinessId, [selectedLinkedInUserId])

      if (success) {
        const linkedInUserToUpdate = linkedInUsers.find(({ _id }) => _id === selectedLinkedInUserId);
        if (linkedInUserToUpdate) {
          const updatedLinkedInUser: LinkedInUser = { ...linkedInUserToUpdate, status: CommercialOutreachStatus.Requested }
          const updatedLinkedInUserList = linkedInUsers.filter(({ _id }) => _id !== selectedLinkedInUserId);
          setLinkedInUsers([ ...updatedLinkedInUserList, updatedLinkedInUser ]);
        }
        setLoading(false);
        closeConfirmationModal();
      }
      else if (overcost) {
        setLoading(false);
        closeConfirmationModal();
        setShowPricingTable(true);
      }
    }
    else {
      closeConfirmationModal();
    }
  };

  // Delete Recipient Modal
  const deleteRecoverClientMessageModalOpen = modalOpen && modalAction === Delete;
  const confirmDeleteRecoverClientMessage = async () => {
    if (selectedLinkedInUserId) {
      await deleteManyLinkedInUsers([selectedLinkedInUserId])
      setLinkedInUsers(linkedInUsers.filter(({ _id }) => _id !== selectedLinkedInUserId));
    }
    closeConfirmationModal();
  };

  const totalRows = rows ? rows.length : 0;
  return (
    <>
      { sendRecoverClientMessageModalOpen && <ConfirmMessageSendModal cancel={closeConfirmationModal} confirm={confirmSendRecoverClientMessage} /> }
      { deleteRecoverClientMessageModalOpen && <DeleteMessageModal cancel={closeConfirmationModal} confirm={confirmDeleteRecoverClientMessage} /> }
      <section className={'commercial-outreach-table-container'}>
        <section className={'table-container'}>
          <Table
            additionalClassNames={['commercial-outreach-table']}
            headers={headers}
            rows={rows}
            cursor={cursor}
            maxRows={RECIPIENTS_MAX_ROWS}
            selectAllRows={selectAllRows}
            hasSelector={hasMessagesToSend}
            hasCounter={true}
          />
        </section>
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={RECIPIENTS_MAX_ROWS}
            totalRows={totalRows}
          />
        </section>
      </section>
    </>
  )
}

export default CommercialOutreachTable;
