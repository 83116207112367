import './DashboardRevenueEditModal.scss';

import { useRef, useState } from 'react';

import { postUpdateBusinessRevenueEstimator } from '../../../../apis/BusinessApi';
import useAuth from '../../../../context/AuthContext';
import { LeadSource } from '../../../../types/LeadTypes';

import Modal from '../../../common/modals/Modal';
import OutlineButton from '../../../common/buttons/OutlineButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import PrimaryInputWithCaretControl from '../../../common/inputs/PrimaryInputWithCaretControl';

interface DashboardRevenueEditModalProps {
  close: () => void,
  confirm: () => void,
  modalRef: React.MutableRefObject<null>,
  leadToClientProportion: string,
  revenuePerClient: string,
  setLeadToClientProportion: (leadToClientProportion: string, leadSource: LeadSource) => void,
  setRevenuePerClient: (revenuePerClient: string, leadSource: LeadSource) => void,
  leadSource: LeadSource,
};

function DashboardRevenueEditModal({ close, confirm, modalRef, leadToClientProportion, revenuePerClient, setLeadToClientProportion, setRevenuePerClient, leadSource } : Readonly<DashboardRevenueEditModalProps>) {
  const { selectedBusinessId } = useAuth();
  const [loading, setLoading] = useState(false);

  const revenueInputRef = useRef<HTMLInputElement>(null);
  const conversionInputRef = useRef<HTMLInputElement>(null);

  const setLeadToClientProportionText = (leadToClientProportion: string) => setLeadToClientProportion(leadToClientProportion, leadSource);
  const setRevenuePerClientText = (revenuePerClient: string) => setRevenuePerClient(revenuePerClient, leadSource);

  const saveEstimatedRevenueSettings = async () => {
    if (selectedBusinessId) {
      setLoading(true);
      await postUpdateBusinessRevenueEstimator(selectedBusinessId, parseInt(leadToClientProportion), parseInt(revenuePerClient), leadSource)
      setLoading(false);
    }
    confirm();
  }

  const handleRevenueCaretPosition = () => {
    const revenueInput = revenueInputRef.current;

    // Ensure caret never goes before index 1 (right after the $)
    if (revenueInput && revenueInput.selectionStart !== null && revenueInput.selectionStart < 1) {
      revenueInput.setSelectionRange(1, 1);
    }
  };

  const handleClientProportionCaretPosition = () => {
    const conversionInput = conversionInputRef.current;

    // Ensure caret never goes before index 1 (right after the $)
    if (conversionInput && conversionInput.selectionStart && conversionInput.selectionStart > conversionInput.value.length - 1) {
      conversionInput.setSelectionRange(conversionInput.value.length - 1, conversionInput.value.length - 1);
    }
  };

  return (
    <Modal additionalClassNames={['dashboard-revenue-edit-modal']}>
      <section ref={modalRef} className={'dashboard-revenue-edit-modal-content'} >
        <h4 className={'title'}>{'Estimated revenue settings'}</h4>
        <h5 className={'subtitle'}>{'Change and update the inputs for the equation'}</h5>
        <section className={'input-container'}>
          <label className={'input-label'} htmlFor={'annual-review-input'}>{'Annual revenue per client'}</label>
          <PrimaryInputWithCaretControl caretController={handleRevenueCaretPosition} inputRef={revenueInputRef} text={`$${revenuePerClient}`} setText={setRevenuePerClientText} placeholder={'$100'} />
        </section>
        <section className={'input-container'}>
          <label className={'input-label'} htmlFor={'lead-to-clients-input'}>{'Lead to client conversion'}</label>
          <PrimaryInputWithCaretControl caretController={handleClientProportionCaretPosition} inputRef={conversionInputRef} text={`${leadToClientProportion}%`} setText={setLeadToClientProportionText} placeholder={'20%'} />
        </section>
        <section className={'buttons'}>
          <OutlineButton content={'Cancel'} onClick={close} />
          <PrimaryButton content={'Confirm'} onClick={saveEstimatedRevenueSettings} loading={loading} />
        </section>
      </section>
    </Modal>
  )
};

export default DashboardRevenueEditModal;