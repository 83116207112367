import '../pages/LeadInboxPage.scss';

import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import useAuth from '../../../../context/AuthContext';
import { useInboxRecipientList } from '../hooks/InboxHooks';

import LeadInboxList from '../common/LeadInboxList';
import LeadConversation from '../common/LeadConversation';
import { InboxRecipientWithLatestMessage } from '../../../people/PeopleConstants';

function LeadInboxPage(): ReactElement {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const navigatedSearchText = state && state.lead ? `${state.lead.firstName} ${state.lead.lastName}` : '';
  const { selectedBusinessId } = useAuth();
  const [selectedRecipient, setSelectedRecipient] = useState<InboxRecipientWithLatestMessage | null>(null);
  const { recipients, setRecipients, loading, searchText, setPage, setSearchText, showOnlyUnread, setShowOnlyUnread, hasMore } = useInboxRecipientList(selectedBusinessId, navigatedSearchText);

  useEffect(() => {
    if (state && state.lead && state.lead.recipientId && recipients) {
      const recipient = recipients.find(({ _id }) => state.lead.recipientId === _id);
      if (recipient) {
        setSelectedRecipient(recipient);
        navigate(pathname, { replace: true, state: null })
      }
    }
  }, [state, recipients])

  const searchForRecipents = (newSearchText: string) => {
    setSearchText(newSearchText);
    setRecipients(null);
    setPage(0);
  }

  const toggleUnreadCheckbox = (unreadValue: boolean) => {
    setShowOnlyUnread(unreadValue);
    setRecipients(null);
    setPage(0);
  }

  useEffect(() => {
    setSelectedRecipient(null);
  }, [selectedBusinessId])

  return (
    <article className={'lead-inbox-page'}>
      <section className={'page'}>
        <section className={'lead-inbox-body'}>
          <section className={'lead-inbox'}>
            <header className={'lead-inbox-header'}>
              <h2 className={'title'}>{'Inbox'}</h2>
              <h3 className={'subtitle'}>{'View all your conversations here'}</h3>
            </header>
          </section>
          <section className={'lead-page-content'}>
            <LeadInboxList
              recipients={recipients}
              setPage={setPage}
              searchText={searchText}
              setSearchText={searchForRecipents}
              hasMore={hasMore}
              loading={loading}
              showOnlyUnread={showOnlyUnread}
              toggleUnreadCheckbox={toggleUnreadCheckbox}
              selectedRecipient={selectedRecipient}
              setSelectedRecipient={setSelectedRecipient}
            />
          </section>
        </section>
        {selectedRecipient && <LeadConversation selectedRecipient={selectedRecipient} />}
      </section>
    </article>
  );
}

export default LeadInboxPage;