import { OptionType } from '../../dropdowns/DropdownConstants';

export enum FilterType {
  DROPDOWN = 'DROPDOWN',
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  ARRAY = 'ARRAY'
}

export enum ConditionText {
  IsExactly = 'Is exactly',
  IsExactlyNot = 'Is exactly not',
  Contains = 'Contains',
  DoesNotContain = 'Does not contain',
  IsEmpty = 'Is empty',
  IsNotEmpty = 'Is not empty',
  IsGreaterThan = 'Is greater than',
  IsLessThan = 'Is less than',
  IsEqual = 'Is equal to',
  IsNotEqual = 'Not equal to',
  IsOneOf = 'Is One Of',
};

export enum ConditionKey {
  IsExactlyKey = 'isExactly',
  IsExactlyNotKey = 'isExactlyNot',
  ContainsKey = 'contains',
  DoesNotContainKey = 'doesNotContain',
  IsEmptyKey = 'isEmpty',
  IsNotEmptyKey = 'isNotEmpty',
  IsGreaterThanKey = 'isGreaterThan',
  IsLessThanKey = 'isLessThan',
  IsEqualKey = 'isEqual',
  IsNotEqualKey = 'isNotEqual',
  IsOneOfKey = 'isOneOf',
};

export interface FilterAttributeMapType {
  [fieldName: string]: {
    key: string,
    displayText: string,
    type: FilterType,
    options?: OptionType<any>[],
  }
}

export interface FilterItemWithKey<Key> {
  attribute: Key | null,
  condition: ConditionKey | null,
  input: string | number,
}

export type FilterItem = {
  attribute: string | null,
  condition: ConditionKey | null,
  input: string | number,
};

interface FilterCondition<T> {
  key: ConditionKey,
  displayText: string,
  valueType: FilterType,
  valueTypeTitle: string,
  filterFunction: (a: T, b: T) => boolean
}

type ConditionType = Record<string, FilterCondition<string | boolean | number | undefined | null>>;
export type FilterConditionMapType = Record<string, ConditionType>;

export type ConditionOptionsMapType = Record<string, OptionType<ConditionKey>[]>;

export type FilterableItemType = any;

export interface FilterableItem {
  [key: string]: FilterableItemType;
};

export const ComparableTypes = [FilterType.STRING, FilterType.NUMBER, FilterType.DATE, FilterType.DROPDOWN];