import './DashboardMetricListContainer.scss';

import { ReactElement } from 'react';

import { Metrics } from '../../../../types/MetricTypes';
import { LeadSource } from '../../../../types/LeadTypes';

import { DashboardMetric } from '../DashboardConstants';

import DashboardMetricItem from './DashboardMetricItem';

interface DashboardMetricProps {
  metricItems: DashboardMetric[]
  metrics: Metrics,
  keyId: string,
  openSettingsModal: ((leadSource: LeadSource) => void) | null
}

function DashboardMetricList({ metricItems, metrics, keyId, openSettingsModal } : Readonly<DashboardMetricProps>): ReactElement<DashboardMetricProps> {
  const metricList = metricItems.map(({ label, icon, tooltipText, fieldName, isEditable, leadSource }) => {
    const count = metrics[fieldName].value;
    const lastUpdateDate = metrics[fieldName].lastUpdateDate;
    return (
      <DashboardMetricItem
        key={`${keyId}-${fieldName}`}
        label={label}
        icon={icon}
        tooltipText={tooltipText}
        count={count}
        lastUpdateDate={lastUpdateDate}
        isEditable={isEditable}
        openSettingsModal={openSettingsModal}
        leadSource={leadSource}
      />
    );
  });
  return <>{metricList}</>
}

export default DashboardMetricList;