import './FilterRow.scss';

import { OptionType } from '../../dropdowns/DropdownConstants';

import DateInput from '../../inputs/DateInput';
import Dropdown from '../../dropdowns/Dropdown';
import PrimaryInput from '../../inputs/PrimaryInput';
import RemovableItem from '../../rows/RemovableItem';

import { ConditionOptionsMap, FilterConditionMap } from '../FilterConstants';
import { ConditionKey, FilterAttributeMapType, FilterType } from '../types/FilterTypes';

const { IsEmptyKey, IsNotEmptyKey } = ConditionKey;
const { DATE } = FilterType;
const EMPTY_CONDITIONS = [IsEmptyKey, IsNotEmptyKey];

interface FilterRowProps {
  attribute: string | null,
  setAttribute: (attribute: string) => void,
  condition: ConditionKey | null,
  setCondition: (condition: ConditionKey) => void,
  input: string | number,
  setInput: (input: number | string) => void,
  filterAttributeMap: FilterAttributeMapType,
  filterOptions: OptionType<string>[],
  deleteRow: () => void,
}

// TODO: Revisit this component to see if we can cut down on how complex it is:
function FilterRow({ attribute, setAttribute, condition, setCondition, input, setInput, deleteRow, filterOptions, filterAttributeMap } : Readonly<FilterRowProps>) {
  const showConditions = attribute !== null;
  const showInput = condition !== null && !EMPTY_CONDITIONS.includes(condition);

  console.log({ filterAttributeMap, attribute })

  // Need to figure out a better way to do this.
  const attributeType = showConditions ? filterAttributeMap[attribute].type : '';

  const dateAttribute = attributeType === DATE;
  const dateInput = dateAttribute && input
    ? new Date(input)
    : new Date();

  let inputField = null;
  if (showConditions && showInput) {
    const conditionObj = FilterConditionMap[attributeType][condition];
    if (conditionObj && conditionObj.valueType === DATE) {
      inputField = <DateInput selectedDate={dateInput} setDate={setInput} />
    }
    else if (conditionObj && conditionObj.valueType === FilterType.DROPDOWN && filterAttributeMap[attribute].options) {
      inputField = (
        <Dropdown
          defaultDisplayText={'Select value'}
          options={filterAttributeMap[attribute].options || []}
          selected={input}
          select={setInput}
          additionalClassNames={['filter-input-dropdown']}
        />
      );
    }
    else {
      inputField = <PrimaryInput placeholder={'Add value'} text={`${input}`} setText={setInput} />;
    }
  }
  
  return (
    <RemovableItem removeItem={deleteRow} additionalClassNames={['filter-row']}>
      <Dropdown defaultDisplayText={'Select attribute'} options={filterOptions} selected={attribute} select={setAttribute} additionalClassNames={['recipient-attributes-dropdown']} />
      { showConditions && attributeType && <Dropdown defaultDisplayText={'Select condition'} options={ConditionOptionsMap[attributeType]} selected={condition} select={setCondition} additionalClassNames={['attribute-conditions-dropdown']} /> }
      { showInput && inputField }
    </RemovableItem>
  );
}

export default FilterRow;