const SECONDS_IN_A_MINUTE = 60;
const SECONDS_IN_AN_HOUR = 60 * SECONDS_IN_A_MINUTE;
const SECONDS_IN_A_DAY = 24 * SECONDS_IN_AN_HOUR;
const SECONDS_IN_A_WEEK = 7 * SECONDS_IN_A_DAY;

export const isSameYear = (date1: Date, date2: Date) => date1.getFullYear() === date2.getFullYear();
export const isSameDay = (date1: Date, date2: Date) => {
  return isSameYear(date1, date2) && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}
export const isYesterday = (date1: Date, date2: Date) => {
  return date1.toDateString() === date2.toDateString();
}

export const getDatestampDisplay = (timestamp: number) => {
  const currentDate = new Date();
  const timestampDate = new Date(timestamp);
  const currentTime = currentDate.getTime();
  // if (isSameDay(currentDate, timestampDate)) {
  //   return 'Today';
  // }
  // const yesterday = new Date();
  // yesterday.setDate(yesterday.getDate() - 1);
  // if (isYesterday(yesterday, timestampDate)) {
  //   return 'Yesterday';
  // }
  // const timeDiff = currentTime - timestamp;
  // if (timeDiff < SECONDS_IN_A_WEEK * 1000) {
  //   return timestampDate.toLocaleString('en-AU', { weekday: 'short'});
  // }
  if (isSameYear(currentDate, timestampDate)) {
    return timestampDate.toLocaleString('en-AU', { month: 'short', day: 'numeric'});
  }
  else {
    return timestampDate.toLocaleString('en-AU', { year: 'numeric', month: 'short', day: 'numeric'});
  }
}

export const getTimestampDisplay = (timestamp: number) => {
  const timestampDate = new Date(timestamp);
  return timestampDate.toLocaleString('en-AU', { hour: 'numeric', hour12: true, minute: 'numeric' });
}