import './LeadInboxList.scss';

import { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { debounce } from 'lodash';

import { postMarkConversationAsRead } from '../../../../apis/MessageApi';
import NavigationConsumer from '../../../../context/NavigationContext';
import useAuth from '../../../../context/AuthContext';
import { InboxRecipientWithLatestMessage } from '../../../people/PeopleConstants';

import SearchInput from '../../../common/inputs/SearchInput';
import InboxRecipientCard from './InboxRecipientCard';
import WhiteCheckbox from '../../../common/checkboxes/WhiteCheckbox';
import LoadingRing from '../../../common/loading/LoadingRing';

interface LeadInboxListProps {
  recipients: InboxRecipientWithLatestMessage[],
  setPage: React.Dispatch<React.SetStateAction<number>>,
  searchText: string,
  setSearchText: (searchText: string) => void,
  hasMore: boolean,
  loading: boolean,
  showOnlyUnread: boolean,
  toggleUnreadCheckbox: (unreadValue: boolean) => void,
  selectedRecipient: InboxRecipientWithLatestMessage | null,
  setSelectedRecipient: React.Dispatch<React.SetStateAction<InboxRecipientWithLatestMessage | null>>,
}

function LeadInboxList({ recipients, setPage, searchText, setSearchText, showOnlyUnread, toggleUnreadCheckbox, hasMore, loading, selectedRecipient, setSelectedRecipient } : LeadInboxListProps) {
  const { selectedBusinessId } = useAuth();
  const { setUpdateNavigationCounts } = NavigationConsumer();
  const [searchTermToUse, setSearchTermToUse] = useState(searchText);
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    setSearchTermToUse('');
  }, [selectedBusinessId])

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string) => {
        setSearchText(value);
        setPage(0); // Reset pagination when search updates
      }, 500), // 500ms debounce delay
    []
  );

  const handleSearchChange = (value: string) => {
    setSearchTermToUse(value);
    debouncedSearch(value);
  }

  useEffect(() => {
    return () => {
      debouncedSearch.cancel(); // Cancel pending debounced calls when component unmounts
    };
  }, []);

  const lastRecipientRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(async (entries) => {
      if (entries[0].isIntersecting) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [setPage]);

  // const recipientsToDisplay = showOnlyUnread ? recipients.filter(recipient => recipient.unreadCount > 0) : recipients;

  const recipientCards = recipients.map((recipient, index) => {
    const hasUnreadMessages = recipient.unreadCount > 0;
    const isSelected = !!selectedRecipient && (selectedRecipient._id === recipient._id);
    const selectRecipient = () => {
      if (hasUnreadMessages) {
        postMarkConversationAsRead(recipient._id)
          .then(response => {
            if (response.success) {
              recipient.unreadCount = 0;
              setUpdateNavigationCounts(true);
            }
          });
      }
      setSelectedRecipient(recipient);
    };
    if (index === recipients.length - 1 && hasMore) {
      
      return (
        <InboxRecipientCard
          lastRecipientRef={lastRecipientRef}
          key={recipient._id}
          recipient={recipient}
          selectRecipient={selectRecipient}
          isSelected={isSelected}
        />
      );
    } else {
      return (
        <InboxRecipientCard
          key={recipient._id}
          recipient={recipient}
          selectRecipient={selectRecipient}
          isSelected={isSelected}
        />
      );
    }
  });

  return (
    <section className={'lead-inbox-list'}>
      <section className={'lead-inbox-list-header'}>
        <SearchInput additionalClassNames={['inbox-search']} text={searchTermToUse} setText={handleSearchChange} placeholder={'Type to search'} />
        <WhiteCheckbox additionalClassNames={['invert-label']} checked={showOnlyUnread} setChecked={toggleUnreadCheckbox} label={'Show only unread'} />
      </section>
      <section className={'lead-inbox-list-body'}>
        { loading
          ? <LoadingRing color={'#000000'} />
          : recipientCards
        }
      </section>
    </section>
  )
}

export default LeadInboxList;