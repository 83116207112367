import './SwapLoginSignUp.scss';

import { useNavigate, useLocation } from 'react-router-dom';

const SignUpLink = 'https://calendly.com/lance-rexlocal/20min';

function SwapLoginSignUp() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const navigateToLogin = () => navigate('/login');

  const switchText = pathname === '/login' ? 'Don\'t have an account?' : 'Already have an account?';
  const signUp = () => {
    window.open(SignUpLink, '_blank');
  }

  const switchButton = pathname === '/login'
    ? <button className={'auth-switch-button'} onClick={signUp} type={'button'}>{'Sign up'}</button>
    : <button className={'auth-switch-button'} onClick={navigateToLogin} type={'button'}>{'Log in'}</button>

  return (
    <section className={'swap-auth-container'}>
      <p className={'has-account-no-account-text'}>{switchText}</p>
      {switchButton}
    </section>
  )
}

export default SwapLoginSignUp;