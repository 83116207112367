import { getData, postData } from './Api';

import { Message, MessageChannel, MessageHistory } from '../components/case-studies/reactivate-clients/MessageConstants';
import { SuccessResponse } from './types/ResponseTypes';
import { InboxRecipientWithLatestMessage, Recipient } from '../components/people/PeopleConstants';

const GetMessageUrls = {
  GetAllRecipientConversationsUrl: '/api/message/inbox/',
  GetUnreadMessageCountUrl: '/api/message/inbox/unread-count/',
  GetRecipientDraftMessageUrl: '/api/message/inbox/draft-message/',
}

const PostMessageUrls = {
  FineTuneMessagesUrl: '/api/message/fineTune',
  UpdateMessageUrl: '/api/message/update',
  QueueMessageForFineTuningUrl: '/api/message/fine-tune/queue',
  UpdateWithStaticMessageUrl: '/api/message/static-message/update',
  RetrieveInboxRecipientsUrl: '/api/message/inbox/recipients',
  SendMessageUrl: '/api/message/inbox/send',
  MarkConversationAsReadUrl: '/api/message/inbox/read',
  SaveDraftMessageUrl: '/api/message/inbox/draft',
}

const {
  GetAllRecipientConversationsUrl,
  GetUnreadMessageCountUrl,
  GetRecipientDraftMessageUrl,
} = GetMessageUrls;

const {
  FineTuneMessagesUrl,
  UpdateMessageUrl,
  QueueMessageForFineTuningUrl,
  UpdateWithStaticMessageUrl,
  RetrieveInboxRecipientsUrl,
  SendMessageUrl,
  MarkConversationAsReadUrl,
  SaveDraftMessageUrl,
} = PostMessageUrls;

// Request interfaces

/** Response from getting all leads for a particular business. */
interface GetAllMessagesFromRecipientResponse extends SuccessResponse {
  recipient: Recipient,
  messages: MessageHistory[],
  hasMore: boolean
}

/** Response from getting all leads for a particular business. */
interface GetUnreadMessageCount extends SuccessResponse {
  totalCount: number
}

/** Response from getting all leads for a particular business. */
interface GetRecipientDraftMessage extends SuccessResponse {
  draftMessage: string
}

/**
 * Request for a list of messages to be fine tuned with a specific prompt and business for context.
 */
interface FineTuneMessagesRequest {
  fineTunePrompt: string,
  messages: Message[],
  businessId: string
}

/**
 * Request for a list of messages to be fine tuned with a specific prompt and business for context.
 */
interface QueueFineTuneMessagesRequest {
  fineTunePrompt: string,
  messages: Message[],
}

/**
 * Request for a list of messages to be updated using supplied static message and personalised variables.
 */
interface StaticMessagesGenerationRequest {
  staticMessage: string,
  messageIds: string[],
  businessId: string
}

/** Request for getting the list of recipients from the inbox based on search string and page. */
interface FindInboxRecipientsRequest {
  businessId: string,
  searchText: string,
  page: number,
  showOnlyUnread: boolean
}

/** Request for updating a list of messages to the backend. */
interface UpdateMessageRequest {
  messages: Message[],
  businessId: string
}

/** Request for getting the list of recipients from the inbox based on search string and page. */
interface SendMessageRequest {
  message: string,
  channel : MessageChannel,
  recipientId: string,
}

/** Request marking a conversation as read. */
interface MarkConversationAsReadRequest {
  recipientId: string,
}

/** Request marking a conversation as read. */
interface SaveDraftMessageRequest {
  recipientId: string,
  draftMessage: string,
}

// Response interfaces

/** List of messages that have been fine tuned. */
interface FineTuneMessagesResponse extends SuccessResponse {
  messages: Message[]
}

/** List of recipients and their latest message */
interface FindInboxRecipientsResponse extends SuccessResponse {
  recipients: InboxRecipientWithLatestMessage[],
  totalCount: number,
  hasMore: boolean,
}

// GET Requests

/**
 * Fetch all leads associated with a business.
 */
export async function getBusinessRecipientConversations(businessId: string, recipientId: string, page: number): Promise<GetAllMessagesFromRecipientResponse> {
  return getData<GetAllMessagesFromRecipientResponse>(`${GetAllRecipientConversationsUrl}${businessId}/${recipientId}/${page}`);
}

/**
 * Fetch total count of unread messages in inbox.
 */
export async function getUnreadMessageCount(businessId: string): Promise<GetUnreadMessageCount> {
  return getData<GetUnreadMessageCount>(`${GetUnreadMessageCountUrl}${businessId}`);
}

/**
 * Fetch total count of unread messages in inbox.
 */
export async function getRecipientDraftMessage(recipientId: string): Promise<GetRecipientDraftMessage> {
  return getData<GetRecipientDraftMessage>(`${GetRecipientDraftMessageUrl}${recipientId}`);
}


// POST Requests

/**
 * Fine tunes messages with a specific prompt for a business.
 * TODO: Deprecate this function
 */
export async function postFineTuneMessagesWithPrompt(
  fineTunePrompt: string,
  messages: Message[],
  businessId: string
): Promise<FineTuneMessagesResponse> {
  return postData<FineTuneMessagesRequest, FineTuneMessagesResponse>(
    FineTuneMessagesUrl, { fineTunePrompt, messages, businessId }
  );
}

/**
 * Updates messages for a business.
 */
export async function postStaticMessageGeneration(
  staticMessage: string,
  messageIds: string[],
  businessId: string
): Promise<SuccessResponse> {
  return postData<StaticMessagesGenerationRequest, SuccessResponse>(
    UpdateWithStaticMessageUrl, { staticMessage, messageIds, businessId }
  );
}

/**
 * Updates messages for a business.
 */
export async function postQueueMessageToFineTune(
  fineTunePrompt: string,
  messages: Message[],
): Promise<SuccessResponse> {
  return postData<QueueFineTuneMessagesRequest, SuccessResponse>(
    QueueMessageForFineTuningUrl, { fineTunePrompt, messages }
  );
}

/**
 * Updates messages for a business.
 */
export async function postUpdateMessages(businessId: string, messages: Message[]): Promise<SuccessResponse> {
  return postData<UpdateMessageRequest, SuccessResponse>(
    UpdateMessageUrl, { messages, businessId }
  );
}

/**
 * Finds recipients with messages to display in the inbox list.
 */
export async function postFindInboxRecipients(businessId: string, searchText: string, page: number, showOnlyUnread: boolean): Promise<FindInboxRecipientsResponse> {
  return postData<FindInboxRecipientsRequest, FindInboxRecipientsResponse>(
    RetrieveInboxRecipientsUrl, { businessId, searchText, page, showOnlyUnread }
  );
}

/**
 * Creates OutboundSMSMessage/OutboundLinkedInMessage to be sent out as a response.
 */
export async function postSendMessage(message: string, channel: MessageChannel, recipientId: string): Promise<SuccessResponse> {
  return postData<SendMessageRequest, SuccessResponse>(
    SendMessageUrl, { message, channel, recipientId }
  );
}

/**
 * Marks a Conversation as read.
 */
export async function postMarkConversationAsRead(recipientId: string): Promise<SuccessResponse> {
  return postData<MarkConversationAsReadRequest, SuccessResponse>(
    MarkConversationAsReadUrl, { recipientId }
  );
}

/**
 * Marks a Conversation as read.
 */
export async function postSaveDraft(recipientId: string, draftMessage: string): Promise<SuccessResponse> {
  return postData<SaveDraftMessageRequest, SuccessResponse>(
    SaveDraftMessageUrl, { recipientId, draftMessage }
  );
}
