import './RecoverClientsManagePage.scss';

import { ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';

import useAuth from '../../../context/AuthContext';
import { RecoverClientsFilterType } from '../../common/multi-select-rows/filters/FilterConstants';
import { EnrichedRecoverClientMessage, FLATFILE_UPLOAD_TYPE, FlatFileTypeKey } from './RecoverClientsConstants';
import { filterRecoverClientMessages } from '../../common/multi-select-rows/filters/FilterHelpers';
import { MessageStatus } from './MessageConstants';
import { postRecoverClientsSendMany } from '../../../apis/RecoverClientsApi';

import Dropdown from '../../common/dropdowns/Dropdown';
import RecipientFlatfileImport from '../../../utils/flatfile/RecipientFlatfileImport';
import InteractionFlatfileImport from '../../../utils/flatfile/InteractionFlatfileImport';
import RecoverClientsTable from './tables/RecoverClientsTable';
import SubtleButton from '../../common/buttons/SubtleButton';
import PrimaryButton from '../../common/buttons/PrimaryButton';

import RecoverClientsFilter from '../common/RecoverClientsFilter';
import SendMessageModal from './modals/SendMessageModal';
import RecoverClientsEmptyPage from './RecoverClientsEmptyPage';
import QuickSelectButton from '../../common/buttons/quick-select/QuickSelectButton';

import ExportToCsvButton from './buttons/ExportToCsvButton';
import LoadingRing from '../../common/loading/LoadingRing';
import { useBusinessReactivateClientMessages } from './hooks/ReactivateClientHooks';
import ShowCompletedToggleButton from '../../common/buttons/composite/ShowCompletedToggleButton';
import GhostButton from '../../common/buttons/GhostButton';

const { Delivered, NotSent } = MessageStatus;

function RecoverClientsManagePage(): ReactElement {
  const navigate = useNavigate();
  const { selectedBusinessId, setShowPricingTable } = useAuth();
  const [showCompleted, setShowCompleted] = useState(true);
  const toggleCompleted = () => setShowCompleted(!showCompleted);
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const openSendModal = () => setSendModalOpen(true);
  const closeSendModal = () => setSendModalOpen(false);

  const { reactivateClientMessages, setReactivateClientMessages, loading, cursor, setCursor } = useBusinessReactivateClientMessages(selectedBusinessId);

  const [flatFileUploadType, setFlatFileUploadType] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<EnrichedRecoverClientMessage[]>([]);

  const [filters, setFilters] = useState<RecoverClientsFilterType[]>([]);

  const filteredRecoverClientMessages = filterRecoverClientMessages(reactivateClientMessages, filters)
    .filter(({ messageStatus }) => showCompleted || messageStatus !== Delivered);

  const quickSelectRows = (amount: number) => {
    const quickSelectedMessages = filteredRecoverClientMessages.filter(({ messageStatus }) => messageStatus === NotSent).slice(0, amount);
    setSelectedRows(quickSelectedMessages);
  }

  const importButton = flatFileUploadType === FlatFileTypeKey.ClientsKey
  ? <RecipientFlatfileImport defaultDisplayText={'Upload file'} />
  : <InteractionFlatfileImport defaultDisplayText={'Upload file'}  />;

  const resetPage = useCallback(() => setCursor(0), [setCursor]);

  const fineTune = () => {
    const messagesToFineTune = selectedRows.length === 0
      ? filteredRecoverClientMessages
      : selectedRows;
    const notSentMessagesToFineTune = messagesToFineTune
      .filter(({ messageStatus }) => messageStatus === NotSent)
      .map(({ _id, recipientName, sendTo, messageStatus, sendDate, channel, message, recipientType })=>
        ({  _id, recipientName, sendTo, messageStatus, sendDate, channel, message, recipientType }));
    navigate('/message/edit', { state: { messages: notSentMessagesToFineTune, originUrl: '/reactivate-clients/manage'  }});
  };

  const sendRecoverClientMessages = async (setLoading: (loading: boolean) => void) => {
    if (selectedBusinessId) {
      const selectedMessages = selectedRows.length > 0 ? selectedRows : reactivateClientMessages;
      const filteredMessages = selectedMessages.filter(selectedMessage => selectedMessage.messageStatus !== Delivered)
      const filteredMessageIds = filteredMessages.map(filteredMessage => filteredMessage._id);
      const { success, overcost } = await postRecoverClientsSendMany(filteredMessageIds, selectedBusinessId);
      if (success) {
        const updatedRecoverClientMessages = reactivateClientMessages.map((reactivateClientMessage) =>
          filteredMessageIds.includes(reactivateClientMessage._id)
            ? ({ ...reactivateClientMessage, messageStatus: Delivered })
            : reactivateClientMessage
        );
        setReactivateClientMessages(updatedRecoverClientMessages);
      }
      else if (overcost) {
        setLoading(false);
        setShowPricingTable(true);
      }
    }
    closeSendModal();
  }
  const hasNoReactivateClientMessages = !loading && reactivateClientMessages.length === 0;
  const hasReactivateClientMessages = !loading && reactivateClientMessages.length > 0;

  const hasNoUnsentMessages = filteredRecoverClientMessages.filter(({ messageStatus }) => messageStatus === NotSent).length === 0;
  const sendMessagesText = selectedRows.length > 0 ? `Send selected` : 'Send all';
  const eligibleForReactivationCount = filteredRecoverClientMessages.filter(({ messageStatus }) => messageStatus === MessageStatus.NotSent).length;
  const reactivationSentCount = filteredRecoverClientMessages.filter(({ messageStatus }) => messageStatus === MessageStatus.Delivered).length;
  const eligibleCountText = `${eligibleForReactivationCount} clients eligible for reactivation, ${reactivationSentCount} clients sent`;
  return (
    <article className={'recover-clients-manage-page'}>
      { sendModalOpen && <SendMessageModal cancel={closeSendModal} confirm={sendRecoverClientMessages} /> }
      <section className={'page'}>
        <header className={'recover-clients-manage-page-header'}>
          <h2 className={'title'}>{'Manage your lost clients'}</h2>
          <h3 className={'subtitle'}>{'View, edit and send messages to the clients who haven’t visited you in a while.'}</h3>
          <section className={'recover-clients-button-dashboard'}>
            {!hasNoReactivateClientMessages && (
              <>
                <section className={'fine-tune-message-buttons'}>
                  <SubtleButton additionalClassNames={['fine-tune-button']} content={'Fine tune'} onClick={fineTune}/>
                  <Dropdown defaultDisplayText={'Upload file'} options={FLATFILE_UPLOAD_TYPE} select={setFlatFileUploadType} selected={flatFileUploadType} />
                  {flatFileUploadType && importButton}
                  <GhostButton additionalClassNames={['show-complete-button']} content={<ShowCompletedToggleButton showCompleted={showCompleted} />} onClick={toggleCompleted} />
                </section>
                <h4 className={'input-subtitle'}>{'Fine tune your message by making notes on specific details you’d like to change in the message.'}</h4>
                <section className={'filter-bar'}>
                  <PrimaryButton additionalClassNames={['send-all-button']} disabled={hasNoUnsentMessages} content={sendMessagesText} onClick={openSendModal} />
                  <QuickSelectButton quickSelectRows={quickSelectRows} />
                  <RecoverClientsFilter
                    filteredRecoverClientMessages={filteredRecoverClientMessages}
                    recoverClientMessages={reactivateClientMessages}
                    resetPage={resetPage}
                    filters={filters}
                    setFilters={setFilters}
                  />
                </section>
              </>
            )}
            {hasNoReactivateClientMessages && <RecoverClientsEmptyPage flatFileUploadType={flatFileUploadType} importButton={importButton} setFlatFileUploadType={setFlatFileUploadType} />}
            <section className={'eligible-clients-count'}>{eligibleCountText}</section>
            {hasReactivateClientMessages && <ExportToCsvButton />}
          </section>
        </header>
        <section className={'recover-clients-page-content'}>
          {loading ? <LoadingRing color={'#000000'} /> : (
            <RecoverClientsTable
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              recoverClientMessages={filteredRecoverClientMessages}
              setRecoverClientMessages={setReactivateClientMessages}
              cursor={cursor}
              setCursor={setCursor}
              hasMessagesToSend={!hasNoUnsentMessages}
            />
          )}
        </section>
      </section>
    </article>
  );
}

export default RecoverClientsManagePage;
