import './ChatRecipientDetails.scss';

import { InboxRecipientWithLatestMessage } from '../../../../people/PeopleConstants';
import { MessageChannel } from '../../../reactivate-clients/MessageConstants';
import Link from '../../../../common/links/Link';
import { determineMessageChannel } from '../../helpers/InboxHelpers';

interface ChatRecipientDetailsProps {
  recipient: InboxRecipientWithLatestMessage
}

function ChatRecipientDetails({ recipient } : ChatRecipientDetailsProps) {
  const fullName = `${recipient?.firstName} ${recipient.lastName}`;
  const lastMessageChannel = recipient?.latestMessage?.channel ? recipient?.latestMessage?.channel : determineMessageChannel(recipient);
  const contactValue = lastMessageChannel === MessageChannel.SMS ? recipient.mobileNumber : recipient.linkedInUrl;
  const formattedContactValue = contactValue ? contactValue : '';
  const channelContactValue = lastMessageChannel === MessageChannel.LINKED_IN && recipient.linkedInUrl
    ? <Link additionalClassNames={['chat-recipient-link']} href={contactValue} content={`${recipient.firstName}'s LinkedIn`} />
    : <p className={'contact-channel-details'}>{formattedContactValue}</p>;
  return (
    <section className={'conversation-contact-details'}>
      <p className={'contact-name'}>{fullName}</p>
      {channelContactValue}
      <p className={'contact-channel'}>{lastMessageChannel}</p>
    </section>
  );
};

export default ChatRecipientDetails;
