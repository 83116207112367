import './LeadsTable.scss';

import { ReactElement, useState } from 'react';

import { Lead } from '../../../../types/LeadTypes';
import { LeadNurtureSettings } from '../../../../types/BusinessTypes';
import { postBeginNurtureFlow, postStopNurtureFlow, postDeleteLead } from '../../../../apis/LeadApi';

import { filterItems } from '../../../common/filters/helpers/FilterHelpers';
import { FilterItem } from '../../../common/filters/types/FilterTypes';

import Table, { TableHeaderProps } from '../../../common/tables/Table';
import PaginationBar from '../../../common/tables/PaginationBar';
import OutlineButton from '../../../common/buttons/OutlineButton';
import SearchInput from '../../../common/inputs/SearchInput';
import Filter from '../../../common/filters/Filter';
import TrashIconButton from '../../../common/buttons/composite/TrashIconButton';

import { LeadsFilterAttributeMap, LeadsFilterOptions, NurtureStatus } from '../LeadsConstants';
import { getNurtureStatus } from '../helpers/LeadHelpers';

import StopNurtureConfirmationModal from '../modals/StopNurtureConfirmationModal';
import DeleteLeadConfirmationModal from '../modals/DeleteLeadConfirmationModal';
import NurtureStatusTag from '../common/NurtureStatusTag';
import { useNavigate } from 'react-router';

const TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'First name', key: 'firstName' },
  { displayText: 'Last name', key: 'lastName' },
  { displayText: 'Mobile number', key: 'mobileNumber' },
  { displayText: 'Email address', key: 'emailAddress' },
  { displayText: 'LinkedIn URL', key: 'linkedInUrl' },
  { displayText: 'Source', key: 'source' },
  { displayText: 'Nurture', key: 'nurture' },
  { displayText: 'Messages', key: 'messages' },
  { displayText: 'Status', key: 'status' },
  { displayText: 'Delete', key: 'delete' },
];

interface LeadsTableProps {
  leads: Lead[],
  setLeads: (leads: Lead[]) => void,
  nurtureSettings: LeadNurtureSettings,
  cursor: number,
  setCursor: React.Dispatch<React.SetStateAction<number>>,
};

const LEADS_MAX_ROWS = 5;

function LeadsTable({ leads, setLeads, nurtureSettings, cursor, setCursor } : Readonly<LeadsTableProps>): ReactElement<LeadsTableProps> {
  const navigate = useNavigate();
  const [loadingIndex, setLoadingIndex] = useState<number[]>([]);
  const [selectedDeleteLeadIndex, selectDeleteLeadIndex] = useState<number | null>(null);
  const [selectedStopNurtureIndex, selectStopNurtureIndex] = useState<number | null>(null);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState<FilterItem[]>([]);
  console.log(leads);
  const filteredLeadsBySearch = leads
    .filter(({ firstName, lastName, mobileNumber, emailAddress }) => {
      const search = searchText.toLowerCase();
      return firstName.toLowerCase().startsWith(search) ||
        lastName.toLowerCase().startsWith(search) ||
        mobileNumber.toLowerCase().startsWith(search) ||
        emailAddress.toLowerCase().includes(search);
    });
  const filteredLeads = filterItems(filteredLeadsBySearch, filters, LeadsFilterAttributeMap);
  const rows = filteredLeads
    .map(({ _id, firstName, lastName, mobileNumber, emailAddress, source, linkedInUrl, nurtureFlow = null, unreadMessageCount = '' }, index) => {
      const firstNameNode = <section className={'two-cell'}>{firstName}</section>;
      const lastNameNode = <section className={'two-cell'}>{lastName}</section>;
      const mobileNumberNode = <section className={'two-cell'}>{mobileNumber}</section>;
      const emailAddressNode = <section className={'three-cell'}>{emailAddress}</section>;
      const fullName = `${firstName}${lastName ? ` ${lastName}` : ''}`;
      const linkedInUrlNode = typeof linkedInUrl === 'string' && linkedInUrl.length > 0 ? (
        <section className={'four-cell'}>
          <a href={linkedInUrl} className={'link-cell'} target={'_blank'} rel={'noopener noreferrer'}>
            <span className={'name-icon'}>{fullName[0].toUpperCase()}</span>
            <span className={'name'}>
              {`${fullName}'s LinkedIn`}
            </span>
          </a>
        </section>
      ) : <section className={'four-cell'} />;
      const sourceNode = <section className={'two-cell'}>{source}</section>;
      const beginNurture = async () => {
        setLoadingIndex([...loadingIndex, index]);
        const response = await postBeginNurtureFlow(_id);
        if (response.success) {
          const newLeads = [...leads];
          newLeads[index].nurtureFlow = response.nurtureFlow;
          setLeads(newLeads);
        }
        setLoadingIndex(loadingIndex.filter((i) => i !== index));
      }
      const stopNurture = () => {
        selectStopNurtureIndex(index);
      }
      const onNurtureClick = nurtureFlow ? stopNurture : beginNurture;
      const { nurtureStatus, nurtureButtonText } = getNurtureStatus(nurtureFlow);
      const nurtureDisabled = !mobileNumber || nurtureSettings.nurtureFlows[source].steps.length === 0 || nurtureStatus === NurtureStatus.Completed;
      const nurtureNode = (
        <section className={'button-cell'}>
          <OutlineButton content={nurtureButtonText} onClick={onNurtureClick} disabled={nurtureDisabled} loading={loadingIndex.includes(index)} />
        </section>
      );
      const messagesButtonText = `View inbox ${unreadMessageCount ? `(${unreadMessageCount})` : ''}`.trim();
      const messagingDisabled = false; // Disable button if lead has no recipientId.
      const navigateToRecipientInbox = () => navigate('/leads/inbox', { state: { lead: filteredLeads[index] }});
      const messagesNode = (
        <section className={'button-cell'}>
          <OutlineButton content={messagesButtonText} onClick={navigateToRecipientInbox} disabled={messagingDisabled} />
        </section>
      );
      const statusNode = (
        <section className={'tag-cell'}>
          <NurtureStatusTag nurtureFlow={nurtureFlow} />
        </section>
      );
      const openDeleteConfirmationModal = () => selectDeleteLeadIndex(index);
      const deleteNode = (
        <section className={'icon-button-cell'}>
          <TrashIconButton onClick={openDeleteConfirmationModal} />
        </section>
      );
      return {
        cells: [
          { key: 'firstName', value: firstNameNode },
          { key: 'lastName', value: lastNameNode },
          { key: 'mobileNumber', value: mobileNumberNode },
          { key: 'emailAddress', value: emailAddressNode },
          { key: 'linkedInUrl', value: linkedInUrlNode },
          { key: 'source', value: sourceNode },
          { key: 'nurture', value: nurtureNode },
          { key: 'messages', value: messagesNode },
          { key: 'status', value: statusNode },
          { key: 'delete', value: deleteNode },
        ],
      }
    });

  const selectSearchText = (text: string) => {
    setCursor(0);
    setSearchText(text);
  }
  const deleteLeadModalOpen = selectedDeleteLeadIndex !== null;
  const closeDeletionModal = () => selectDeleteLeadIndex(null);
  const deleteLead = () => {
    if (deleteLeadModalOpen) {
      const lead = leads[selectedDeleteLeadIndex];
      postDeleteLead(lead._id);
      setLeads(leads.filter((_, i) => i !== selectedDeleteLeadIndex));
      closeDeletionModal();
    }
  }
  const stopNurtureModalOpen = selectedStopNurtureIndex !== null;
  const closeStopNurtureModal = () => selectStopNurtureIndex(null);
  const confirmStopNurture = async (setLoading: (loading: boolean) => void) => {
    if (stopNurtureModalOpen) {
      const lead = leads[selectedStopNurtureIndex];
      setLoading(true);
      const response = await postStopNurtureFlow(lead._id);
      setLoading(false);
      if (response.success) {
        const newLeads = [...leads];
        newLeads[selectedStopNurtureIndex].nurtureFlow = response.nurtureFlow;
        setLeads(newLeads);
      }
      closeStopNurtureModal();
    }
  }
  return (
    <>
      { stopNurtureModalOpen && <StopNurtureConfirmationModal cancel={closeStopNurtureModal} confirm={confirmStopNurture} /> }
      { deleteLeadModalOpen && <DeleteLeadConfirmationModal cancel={closeDeletionModal} confirm={deleteLead} />}
      <section className={'leads-table-container'}>
        <section className={'search-bar-container'}>
          <SearchInput placeholder={'Type to search'} text={searchText} setText={selectSearchText} />
          <Filter
            filteredRows={filteredLeads}
            rows={leads}
            filters={filters}
            setFilters={setFilters}
            searchText={searchText}
            filterAttributeMap={LeadsFilterAttributeMap}
            filterOptions={LeadsFilterOptions}
          />
        </section>
        <section className={'table-container'}>
          <Table
            additionalClassNames={['leads-table']}
            headers={TABLE_HEADERS}
            rows={rows}
            cursor={cursor}
            maxRows={LEADS_MAX_ROWS}
          />
        </section>
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={LEADS_MAX_ROWS}
            totalRows={rows.length}
          />
        </section>
      </section>
    </>
  )
}

export default LeadsTable;

