import { InboxRecipientWithLatestMessage } from "../../../people/PeopleConstants";
import { MessageChannel, MessageDirection, MessageHistory, MessageSource } from "../../reactivate-clients/MessageConstants";

export function generateMessageHistory(recipient: InboxRecipientWithLatestMessage, businessId: string, message: string, channel: MessageChannel): MessageHistory {
  return{
    message,
    businessId,
    creationDate: Date.now(),
    readDate: Date.now(),
    direction: MessageDirection.Outgoing,
    recipientId: recipient._id,
    channel,
    lastMessageReminderDate: null,
    source: MessageSource.LeadNurture,
  };
}

export function determineMessageChannel(recipient: InboxRecipientWithLatestMessage) {
  const { mobileNumber, linkedInUrl, conversationId } = recipient;
  if (mobileNumber) {
    return MessageChannel.SMS;
  }
  else if (conversationId || linkedInUrl) {
    return MessageChannel.LINKED_IN;
  }
  return null;
};